import httpApi from '../httpApi';

class CallService {
	generateSignature(data) { // zoom web sdk
		return httpApi.put('/zoom/signature', data);
	}
	
	getCalls() { // zoom api
		return httpApi.get('/calls');
	}
	
	createLicencedUser(data) { // zoom api
		return httpApi.put('/zoom/user', data);
	}
	
	deleteLicencedUser(id) { // zoom api
		return httpApi.delete('/zoom/user/'+id);
	}
	
	getMeeting(id) { // zoom api
		return httpApi.get('/zoom/meetings/'+id);
	}
	
	createCall(data) { // zoom api
		return httpApi.post('/zoom/meetings', data);
	}
	
	updateMeeting(data) { // zoom api
		return httpApi.put('/zoom/meetings', data);
	}
	
	cancelMeeting(id) { // zoom api
		return httpApi.patch('/zoom/meetings/'+id);
	}
}

export default new CallService();
