// user data service
import httpApi from '../httpApi';

class UserService {
	// api calls
	getUsers() {
		return httpApi.get('/users');
	}

	getMessages() {
		return httpApi.get('/users/messages');
	}

	createUser(data) {
		return httpApi.put('/users', data);
	}

	updateUser(data) {
		return httpApi.post('/users', data);
	}

	updateUserOrganisation(data) {
		return httpApi.post('/users/userOrganisation', data);
//		httpApi.post('/users/userOrganisation', data).then(res => {
//			return res;
//		});
	}

	updateUserOnlineStatus(data) {
		return httpApi.post('/users/onlineStatus', data);
	}
//	archiveUser(data) {
//		return httpApi.post('/users/archive', data);
//	}
}

export default new UserService();
