import UserService from '../services/UserService';
import { getField, updateField } from 'vuex-map-fields';
import router from '../router';

export const users = {
	namespaced: true,
	state: {
		users: [],
		messages: [],
		userRoles: [
			{
				id: 6,
				name: 'Learner',
				slug: 'learner',
				seniority: 60,
			},
			{
				id: 5,
				name: 'Mentor',
				slug: 'mentor',
				seniority: 50,
			},
			{
				id: 4,
				name: 'Admin',
				slug: 'admin',
				seniority: 40,
			},
			{
				id: 3,
				name: 'Owner',
				slug: 'owner',
				seniority: 30,
			},
			{
				id: 2,
				name: 'Principal',
				slug: 'principal',
				seniority: 20,
			},
			{
				id: 1,
				name: 'Developer',
				slug: 'developer',
				seniority: 10,
			},
		],
	},
	getters: {
		getField,
		getUser: (state, getters) => (userId) => {
			const userIndex = getters.getUserIndex(userId);
			
			return (userIndex < 0) ? null : state.users[userIndex];
		},
		getUserIndex: (state) => (userId) => {
			// find array index for user
			return state.users.findIndex(u => u.id === parseInt(userId));			
		},
		getUserRoleField(state, getters) {
			// get user id from route params
			const userId = router.currentRoute._value.params.userId;
			const userIndex = getters.getUserIndex(userId);
			
			if (userIndex < 0) {
				// store not loaded or bad id request
				return () => {
					return null;
				};
				
			} else if (state.users[userIndex].userOrganisations) {
				// return field
				return getField(state.users[userIndex].userOrganisations[0]);
			}		
		},
		getUserField(state, getters, rootState) {
			const routeName = router.currentRoute._value.name;
			
			if (routeName === 'ManageAccountProfile') {
				// editing own account
				return getField(rootState.auth.user);
			}
			
			// get user id from route params
			const userId = router.currentRoute._value.params.userId;
			const userIndex = getters.getUserIndex(userId);
			
			if (userIndex < 0) {
				// store not loaded or bad id request
				return () => {
					return null;
				};
				
			} else {
				// return field
				return getField(state.users[userIndex]);
			}
		},
		userOnline: (state, getters, rootState) => (userId) => {
			return state.users.find(u => u.id === userId && u.online === rootState.gui.organisation.id) ? true : false;
		},
	},
	mutations: {
		updateField,
		updateUserRoleField(state, field) {
			// get user id from route params
			const userId = router.currentRoute._value.params.userId;
			const userIndex = state.users.findIndex(u => u.id === parseInt(userId));
			
			const ref = state.users[userIndex].userOrganisations[0];
			
			updateField(ref, {
				path: 'role',
				value: state.userRoles.find(r => r.id === parseInt(field.value)),
			});
		},
		updateUserField(state, field) {
			const routeName = router.currentRoute._value.name;
			const authRef = this.state.auth.user;
			let ref = null;
			let account = false;
			
			if (routeName === 'ManageAccountProfile') {
				account = true;
				ref = authRef;
				
			} else {
				// get user id from route params
				const userId = router.currentRoute._value.params.userId;
				// find array index for user
				const userIndex = state.users.findIndex(u => u.id === parseInt(userId));
				
				ref = state.users[userIndex];				
			}
			
			updateField(ref, field);
			
			if (field.path === 'firstName' || field.path === 'lastName') {
				//	update fullName	
				updateField(ref, {
					path: 'fullName',
					value: this.state.auth.user.firstName +  ' ' + this.state.auth.user.lastName,
				});
				
				//	update initials
				updateField(ref, {
					path: 'initials',
					value: this.state.auth.user.firstName[0].toUpperCase() + this.state.auth.user.lastName[0].toUpperCase(),
				});
			}
			
			if (!account && (ref.id === this.state.auth.user.id)) {
				// updating self via user admin, so update auth user also
				updateField(authRef, field);
				updateField(authRef, { path: 'fullName', value: ref.fullName });
				updateField(authRef, { path: 'initials', value: ref.initials });
			}
		},
		ADD_USER(state, payload) {
			state.users.push(payload);
		},
		SET_USERS(state, payload) {
			state.users = payload;
		},
		SET_MESSAGES(state, payload) {
			state.messages = payload;
		},
		SET_TYPING(state, payload) {
			state.users[payload.index].typing = payload.typing;
		},
		SET_MESSAGE(state, payload) {
			state.messages.push(payload);
		},
		SET_MESSAGE_READ(state, payload) {
			state.messages[payload.index].read = payload.read;
		},
		SET_STATUS(state, payload) {
			state.users[payload.userIndex].userOrganisations[0].status = payload.status;
		},
		SET_USER_ONLINE(state, payload) {
			state.users[payload.userIndex].online = payload.status;
		},
	},
	actions: {
		async dbSetStatus({ commit, state }, data) {
			try {
				const userIndex = state.users.findIndex(u => u.id === data.userId);

				const response = await UserService.updateUserOrganisation({
					userId: data.userId,
					status: data.status,
				});

				if (userIndex >= 0 && response) {
					commit('SET_STATUS', {
						userIndex: userIndex,
						status: data.status,
					});
				}

			} catch (error) {
				console.log(error);
			}
		},
		async dbCreateUsers({ dispatch }, data) {
			try {
				for (const to of data.to) {
					await UserService.createUser({
						from: data.from || null,
						email: to.email,
						firstName : to.firstName,
						lastName : to.lastName,
						groupName : to.groupName || null,
						roleId : to.roleId || null,
						//tempPassword : to.tempPassword || null,
					});
				}
				
				// reload all users
				dispatch('dbUsers');
				
				return 'done';
				
			} catch (error) {
				console.log(error);
			}
		},
		async dbUsers({ commit }) {
			// get organisation users from database
			try {
				const {data} = await UserService.getUsers();
				
				commit('SET_USERS', data.users);
				
			} catch (error) {
				console.log(error);
			}
		},
		async dbMessages({ commit }) {
			// get user messages from other users in organisation from database
			try {
				const {data} = await UserService.getMessages();
				commit('SET_MESSAGES', data.messages);

			} catch (error) {
				console.log(error);
			}
		},
		setTyping({ commit, state }, data) {
			const index = state.users.findIndex(u => u.id === data.fromId);

			if (index >= 0) {
				commit('SET_TYPING', {
					index: index,
					typing: data.typing,
				});
			}
		},
		setMessage({ commit }, data) {
			commit('SET_MESSAGE', data);
		},
		setMessageRead({ commit, state }, data) {
			for(let message of data.readMessages) {
				const index = state.messages.findIndex(m => m.id === message.id);
				commit('SET_MESSAGE_READ', {
					index: index,
					read: message.read,
				});
			}
		},
		async updateOnlineUsers({ commit, state }, data) {
			try {
				for(let i in state.users) {
					const user = data.find(u => u.userId === state.users[i].id) || null;
					const status = (user) ? user.organisationId : 0;

					commit('SET_USER_ONLINE', {
						userIndex: i,
						status: status,
					});
				}

			} catch (error) {
				console.log(error);
			}
		},
	},
};
