import axios from 'axios';

const apiBaseUrl = (process.env.VUE_APP_VUE_ENV === 'development') ? process.env.VUE_APP_API_BASE_URL : window.location.origin + '/api';

axios.defaults.withCredentials = true;

export default axios.create({
	baseURL: apiBaseUrl,
	headers: {
		'Content-type': 'application/json'
	},
});
