import CallService from '../services/CallService';

export const calls = {
	namespaced: true,
	state: {
		meetings: [],
		webinars: [], // phase 2
		licensedUsers: [],
		tempIndex: 0,
	},
	mutations: {
		DESC_TEMP_INDEX(state) {
			state.tempIndex --;
		},
		SET_MEETINGS(state, payload) {
			state.meetings = payload;
		},
		SET_LICENSED_USERS(state, payload) {
			state.licensedUsers = payload;
		},
		ASSIGN_MEETING_USER(state, payload) {
			state.meetings[payload.meetingIndex].attendees.push(payload.user);
		},
		UNASSIGN_MEETING_USER(state, payload) {
			state.meetings[payload.meetingIndex].attendees.splice(payload.userIndex, 1);
		},
	},
	actions: {
		async dbCalls({ commit }) {
			// get organisation courses from database
			try {
				const {data} = await CallService.getCalls();

				commit('SET_MEETINGS', data.calls);
				commit('SET_LICENSED_USERS', data.licensedUsers);

			} catch (error) {
				console.log(error);
			}
		},
		async dbAddCall({ commit }, data) {
//			commit('DESC_TEMP_ID');
			if (commit) {
			const response = await CallService.createCall(data);
console.log('dbAddCall:', data, response);
			}
//			commit('ADD_CALL', {
//				courseIndex: courseIndex,
//			});

			// insert into user_organisation_call (user_organisation_id,zoom_meeting_id) values (2,83038508886), (3,83038508886), (4,83038508886);
		},
		assignUsers({ commit, state }, data) {
			const meetingIndex = state.meetings.findIndex(m => m.meetingNumber === data.meetingNumber);

			for (const user of data.users) {
				commit('ASSIGN_MEETING_USER', {
					meetingIndex,
					user,
				});
			}

		},
		unassignUsers({ commit, state }, data) {
			const meetingIndex = state.meetings.findIndex(m => m.meetingNumber === data.meetingNumber);

			for (const user of data.users) {
				const userIndex = state.meetings[meetingIndex].attendees.findIndex(u => u.id === parseInt(user.id));

				commit('UNASSIGN_MEETING_USER', {
					meetingIndex,
					userIndex,
				});
			}
		},
	},
	getters: {
/*			getUserCalls: (state, getters, rootstate) => {
			// get courses where logged in user is a course user
			let userCalls = [];

			for (const c of state.calls) {
				if (c.courseUsers.length) {
					for (const cu of c.courseUsers) {
						if (cu && cu.userOrganisation.userId === rootstate.auth.user.id) {
							userCourses.push(c);
						}
					}
				}
			}
			return userCalls || null;
		},
*/
	}
};
