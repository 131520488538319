// auth data service
import httpApi from '../httpApi';

class AuthService {
	status() {
		return httpApi.get('/auth/status');
	}
	
	identify(data) {
		return httpApi.post('/auth/identify', {
			email: data.email
		});
	}
	
	signup(data) {
		return httpApi.post('/auth/signup', {
			email: data.email,
			password: data.password
		});
	}
	
	verify(data) {
		return httpApi.post('/auth/verify', {
			verification: data.verification
		});
	}
	
	signin(data) {
		return httpApi.post('/auth/signin', {
			email: data.email,
			password: data.password,
			passcode: data.passcode || null,
		});
	}
	
	recover(data) {
		return httpApi.post('/auth/recover', {
			email: data.email
		});
	}

	signout() {
		return httpApi.get('/auth/signout');
	}
}

export default new AuthService();
