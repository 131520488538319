<template lang="pug">
include ../pug/svg
div.field(:class="class")
	span.input(v-if="append&&showAppend" :class="{disabled:disabled}")
		em {{appendValue}}
		em {{append}}
	Field(:type="type" :placeholder="placeholder" autocomplete="off" v-model="value" :name="name" :validateOnInput="false" @keydown="keydown" @keyup="keyup" @blur="blur" :required="required" :disabled="disabled" :maxlength="maxlength")
	label {{label}}
	span.err(:class="{hide:cancelled||!errors[name]||errors[name]==='-'}") {{errors[name]}}
	//-pre valueB: {{valueB}}
</template>

<script>
import { Field } from 'vee-validate';

export default {
	name: 'FieldText',
	props: {
		modelValue: [String, Number],
		name: {
			type: String,
			default: null
		},
		label: String,
		errors: {
			type: Object,
			default: () => ({})
		},
		cancelled: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: null
		},
		class: {
			type: String,
			default: null
		},
		maxlength: {
			type: Number,
			default: null			
		},
		numeric: {
			type: Boolean,
			default: false			
		},
		append: {
			type: String,
			default: null			
		},
		type: {
			type: String,
			default: 'text',
			validator(value) {
				// The value must match one of these strings
				return ['text', 'password', 'email', 'search', 'number', 'tel', 'date', 'time'].includes(value);
			},
		},
	},
	emits: ['update:modelValue','keydown','keyup','blur'],
	components: {
		Field,
	},
	data() {
		return {
			placeholder: 'Unsupplied',
			allowedKeys: ['Backspace','Shift','Tab','ArrowLeft','ArrowRight','ArrowUp','ArrowDown','0','1','2','3','4','5','6','7','8','9'],
		}
	},
	computed: {
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit('update:modelValue', value);
			}
		},
		appendValue() {
			return (this.value !== '') ? this.value : this.placeholder;
		},
		showAppend() {
			return (this.numeric) ? (this.value !== '' && this.value !== null) : this.value;
		}
	},
	methods: {
		keydown(event) {
			if (this.numeric && this.allowedKeys.indexOf(event.key) < 0) {
				// restricted key
				event.preventDefault();
			}
			
			this.$emit('keydown', event);
		},
		keyup(event) {
			this.$emit('keyup', event);
		},
		blur(event) {
			this.$emit('blur', event);			
		},
	},
}
</script>

<style lang="scss">
</style>
