// socket io data service
import { io } from 'socket.io-client';
import store from '../store';

class SocketioService {
	socket;
	$store = store;
	socketEndpoint = (process.env.VUE_APP_VUE_ENV === 'development') ? process.env.VUE_APP_SOCKET_ENDPOINT : window.location.origin;

	// inward bound notifications
	connect() { // called from ThinkHub.vue
//		this.socket = io(process.env.VUE_APP_SOCKET_ENDPOINT, {
		this.socket = io(this.socketEndpoint, {
			withCredentials: true,
		});

		this.socket.on('user-online', (data) => {
			// update online users in store
//console.log('user came online:', data);
			this.$store.dispatch('users/updateOnlineUsers', data.users);
		});

		this.socket.on('user-offline', (data) => {
			// update online users in store
//console.log('user went offline:', data);
			this.$store.dispatch('users/updateOnlineUsers', data.users);
		});

		this.socket.on('user-leaving', (data) => {
			// update online users in store
//console.log('user leaving:', data);
			this.$store.dispatch('users/updateOnlineUsers', data.users);
		});

		this.socket.on('message-read', (data) => {
			// private message received from specific user
//console.log('user-user your message(s) have beed read:', data);
			this.$store.dispatch('users/setMessageRead', { readMessages: data });
		});

		this.socket.on('user-typing', (data) => {
			// private typing flag received from specific user
//console.log('user-user typing received:', data);
			this.$store.dispatch('users/setTyping', data);
		});

		this.socket.on('user-message', (data) => {
			// private message received from specific user
//console.log('user-user message received:', data);
			this.$store.dispatch('users/setMessage', data);
		});

		this.socket.on('hubroom-message', (data) => {
			if (data) { // prevent data unused error
				return;	
			}
			// message received from specific user broadcast to hub
//console.log('user-hubroom message received:', data);
		});
	}

	// outward bound notifications
	disconnect() {
		if (this.socket) {
			this.socket.disconnect();
		}
	}

	typingToUser(data) {
		if (this.socket) {
//console.log('SocketioService:typingToUser():', data);
			this.socket.emit('typing-user', data);
		}
	}

	messageToUser(data, callback) {
		if (this.socket) {
//console.log('SocketioService:messageToUser():', data);
			this.socket.emit('message-user', data, callback);
		}
	}

	userMessageRead(data, callback) {
		if (this.socket) {
//console.log('SocketioService:userMessageRead():', data);
			this.socket.emit('message-read', data, callback);
		}
	}

	userSwitchingOrganisation() {
		if (this.socket) {
//console.log('SocketioService.userSwitchingOrganisation()');
			this.socket.emit('user-leaving');
		}
	}

//	messageHub(data) {}

	userTyping() {
		if (this.socket) {
//			this.socket.emit('user-typing', 'Hello from Vue.');
		}
	}
}

export default new SocketioService();
