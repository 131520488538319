import store from '../store';
import { createRouter, createWebHistory } from 'vue-router';

// preload views
import Sign from '../views/Sign.vue';
import Signin from '../views/Signin.vue';
import Signup from '../views/Signup.vue';
import Recover from '../views/Recover.vue';
import ThinkHub from '../views/ThinkHub.vue';
//import Home from '../views/Home.vue';
import Error404 from '../views/Error404.vue';
import Course from '../views/Course.vue';

// load each view when route visited
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue');
//const Help = () => import(/* webpackChunkName: "help" */ '../views/Help.vue');
const Messages = () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue');
const Calls = () => import(/* webpackChunkName: "calls" */ '../views/Calls.vue');
const Learn = () => import(/* webpackChunkName: "learn" */ '../views/Learn.vue');
const ManageLearning = () => import(/* webpackChunkName: "manageLearning" */ '../views/ManageLearning.vue');
const ManageLearningCourse = () => import(/* webpackChunkName: "manageLearningCourse" */ '../views/ManageLearningCourse.vue');
//const ManageLearningCoursePreview = () => import(/* webpackChunkName: "manageLearningCoursePreview" */ '../views/ManageLearningCoursePreview.vue');
const ManageLearningCourseContent = () => import(/* webpackChunkName: "manageLearningCourseContent" */ '../views/ManageLearningCourseContent.vue');
const ManageLearningCourseLesson = () => import(/* webpackChunkName: "manageLearningCourseLesson" */ '../views/ManageLearningCourseLesson.vue');
const ManageLearningCourseSettings = () => import(/* webpackChunkName: "manageLearningCourseSettings" */ '../views/ManageLearningCourseSettings.vue');
const ManageLearningCourseAccess = () => import(/* webpackChunkName: "manageLearningCourseAccess" */ '../views/ManageLearningCourseAccess.vue');
//const ManageLearningCoursePrerequisits = () => import(/* webpackChunkName: "manageLearningCoursePrerequisits" */ '../views/ManageLearningCoursePrerequisits.vue');
const ManageLearningCourseActivity = () => import(/* webpackChunkName: "manageLearningCourseActivity" */ '../views/ManageLearningCourseActivity.vue');
const ManageLearningCourseFeedback = () => import(/* webpackChunkName: "manageLearningCourseFeedback" */ '../views/ManageLearningCourseFeedback.vue');
const ManageMedia = () => import(/* webpackChunkName: "manageMedia" */ '../views/ManageMedia.vue');
const ManagePeople = () => import(/* webpackChunkName: "managePeople" */ '../views/ManagePeople.vue');
const ManagePeopleUser = () => import(/* webpackChunkName: "managePeopleUser" */ '../views/ManagePeopleUser.vue');
//const ManagePeopleUserOverview = () => import(/* webpackChunkName: "managePeopleUserOverview" */ '../views/ManagePeopleUserOverview.vue');
const ManagePeopleUserProfile = () => import(/* webpackChunkName: "managePeopleUserProfile" */ '../views/ManagePeopleUserProfile.vue');
//const ManagePeopleUserLearning = () => import(/* webpackChunkName: "managePeopleUserLearning" */ '../views/ManagePeopleUserLearning.vue');
//const ManagePeopleUserActivity = () => import(/* webpackChunkName: "managePeopleUserActivity" */ '../views/ManagePeopleUserActivity.vue');
const ManageTheme = () => import(/* webpackChunkName: "manageTheme" */ '../views/ManageTheme.vue');
const ManageThemeStyle = () => import(/* webpackChunkName: "manageThemeStyle" */ '../views/ManageThemeStyle.vue');
const ManageThemeFont = () => import(/* webpackChunkName: "manageThemeFont" */ '../views/ManageThemeFont.vue');
const ManageThemeColour = () => import(/* webpackChunkName: "manageThemeColour" */ '../views/ManageThemeColour.vue');
const ManageThemeTerminology = () => import(/* webpackChunkName: "manageThemeTerminology" */ '../views/ManageThemeTerminology.vue');
//const ManageThemeCss = () => import(/* webpackChunkName: "manageThemeCss" */ '../views/ManageThemeCss.vue');
const ManageAccount = () => import(/* webpackChunkName: "manageAccount" */ '../views/ManageAccount.vue');
const ManageAccountFeature = () => import(/* webpackChunkName: "manageAccountFeature" */ '../views/ManageAccountFeature.vue');
const ManageAccountOrganisation = () => import(/* webpackChunkName: "manageAccountOrganisation" */ '../views/ManageAccountOrganisation.vue');
//const ManageAccountBilling = () => import(/* webpackChunkName: "manageAccountBilling" */ '../views/ManageAccountBilling.vue');
//const CourseSynopsis = () => import(/* webpackChunkName: "courseSynopsis" */ '../views/CourseSynopsis.vue');
const CourseProgress = () => import(/* webpackChunkName: "courseProgress" */ '../views/CourseProgress.vue');

const routes = [
	{
		path: '/',
		redirect: '/dashboard',
	},
	{
		path: '/', // unused
		name: 'Sign',
		component: Sign,
		meta: {
			requireLogin: false, // inherited by child routes
		},
		children: [
			{
				path: '/signup',
				name: 'Signup',
				component: Signup,
				meta: {},
			},
			{
				path: '/signin',
				name: 'Signin',
				component: Signin,
				meta: {},
			},
			{
				path: '/recover',
				name: 'Recover',
				component: Recover,
				meta: {},
			},
			{
				path: '', // default
				redirect: { name: 'Signin' }
			},
		]
	},
	{
		path: '/', // unused
		name: 'Course',
		component: Course,
		meta: {
			requireLogin: true, // inherited by child routes
		},
		children: [
			/*{
				path: '/:courseId/synopsis',
				name: 'CourseSynopsis',
				component: CourseSynopsis,
				meta: {},
			},*/
			{
				path: '/:courseId/progress/:lessonId',
				name: 'CourseProgress',
				component: CourseProgress,
				meta: {},
			},
		],
	},
	{
		path: '/', // unused
		name: 'ThinkHub',
		component: ThinkHub,
		meta: {
			requireLogin: true, // inherited by child routes
		},
		children: [
			{
				path: '/dashboard',
				name: 'Dashboard',
				component: Dashboard,
				meta: {},
			},
			/*{
				path: '/help',
				name: 'Help',
				component: Help,
				meta: {},
			},*/
			{
				path: '/messages', // messenge management
				name: 'Messages',
				component: Messages,
				meta: { 
					//seniority: 60,
					feature: 'messages',
					subfeature: null,
				},
			},
			{
				path: '/calls', // zoom call management
				name: 'Calls',
				component: Calls,
				meta: { 
					//seniority: 60,
					feature: 'calls',
					subfeature: null,
				},
			},
			{
				path: '/learn',
				redirect: '/learn/active',
			},
			{
				path: '/learn/:state', // active or complete
				name: 'Learn',
				component: Learn,
				props: true,
				meta: {},
			},
			{
				path: '/manage/learning', // course list
				name: 'ManageLearning',
				component: ManageLearning,
				meta: { 
					//seniority: 50,
					feature: 'course',
					subfeature: null,
				},
			},
			/*{
				path: '/manage/learning/:courseId/preview', // course preview
				name: 'ManageLearningCoursePreview',
				component: ManageLearningCoursePreview,
				meta: { 
					//seniority: 50,
					feature: 'course',
					subfeature: 'content',
				},
			},*/
			{
				path: '/manage/learning/:courseId', // course parent
				name: 'ManageLearningCourse',
				component: ManageLearningCourse,
				meta: { 
					//seniority: 40,
					feature: 'course',
					subfeature: 'content',
				},
				children: [
					{
						path: 'content', // course content list + section management
						name: 'ManageLearningCourseContent',
						component: ManageLearningCourseContent,
						meta: { 
							//seniority: 40,
							feature: 'course',
							subfeature: 'content',
						},
					},
					{
						path: 'settings', // course setting management
						name: 'ManageLearningCourseSettings',
						component: ManageLearningCourseSettings,
						meta: {
							//seniority: 40,
							feature: 'course',
							subfeature: 'settings',
						},
					},
					{
						path: 'access', // course access management
						name: 'ManageLearningCourseAccess',
						component: ManageLearningCourseAccess,
						meta: {
							//seniority: 40,
							feature: 'course',
							subfeature: 'access',
						},
					},
					{
						path: 'activity', // course prerequisit management
						name: 'ManageLearningCourseActivity',
						component: ManageLearningCourseActivity,
						meta: {
							//seniority: 50,
							feature: 'course',
							subfeature: 'activity',
						},
					},
					{
						path: 'feedback', // course feedback management
						name: 'ManageLearningCourseFeedback',
						component: ManageLearningCourseFeedback,
						meta: {
							//seniority: 50,
							feature: 'course',
							subfeature: 'activity',
						},
					},
					{
						path: 'lesson/:lessonId', // course lession edit
						name: 'ManageLearningCourseLesson',
						component: ManageLearningCourseLesson,
						meta: {
							//seniority: 40,
							feature: 'course',
							subfeature: 'content',
						},
					},
				],
			},
			{
				path: '/manage/media', // media list + management
				name: 'ManageMedia',
				component: ManageMedia,
				meta: {
					//seniority: 40,
					feature: 'media',
					subfeature: null,
				},
			},
			{
				path: '/manage/people', // people list
				name: 'ManagePeople',
				component: ManagePeople,
				meta: {
					//seniority: 50,
					feature: 'people',
					subfeature: null,
				},
			},
			{
				path: '/manage/people/:userId', // individual
				name: 'ManagePeopleUser',
				component: ManagePeopleUser,
				meta: {
					//seniority: 50,
					feature: 'people',
					subfeature: null,
				},
				children: [
					/*{
						path: 'overview', // individual profile overview
						name: 'ManagePeopleUserOverview',
						component: ManagePeopleUserOverview,
						meta: {
							//seniority: 50,
							feature: 'people',
							subfeature: null,
						},
					},*/
					{
						path: 'profile', // individual profile management
						name: 'ManagePeopleUserProfile',
						component: ManagePeopleUserProfile,
						meta: {
							//seniority: 50,
							feature: 'people',
							subfeature: null,
						},
					},
					/*{
						path: 'learning', // individual learning management
						name: 'ManagePeopleUserLearning',
						component: ManagePeopleUserLearning,
						meta: {
							//seniority: 50,
							feature: 'people',
							subfeature: null,
						},
					},*/
					/*{
						path: 'activity', // individual activity
						name: 'ManagePeopleUserActivity',
						component: ManagePeopleUserActivity,
						meta: {
							//seniority: 50,
							feature: 'people',
							subfeature: null,
						},
					},*/
				],
			},
			{
				path: '/manage/account', // individual
				name: 'ManageAccount',
				component: ManageAccount,
				meta: {
					//seniority: 60,
					feature: 'account',
					subfeature: null,
				},
				children: [
					{
						path: 'profile',
						name: 'ManageAccountProfile',
						component: ManagePeopleUserProfile, // reused
						meta: {
							//seniority: 60,
							feature: 'account',
							subfeature: 'profile',
						},
					},
					{
						path: 'organisation',
						name: 'ManageAccountOrganisation',
						component: ManageAccountOrganisation,
						meta: {
							//seniority: 30,
							feature: 'account',
							subfeature: 'organisation',
						},
					},
					{
						path: 'feature',
						name: 'ManageAccountFeature',
						component: ManageAccountFeature,
						meta: {
							//seniority: 20,
							feature: 'account',
							subfeature: 'feature',
						},
					},
					/*{
						path: 'billing',
						name: 'ManageAccountBilling',
						component: ManageAccountBilling,
						meta: {
							//seniority: 30,
							feature: 'account',
							subfeature: 'billing',
						},
					},*/
				],
			},
			{
				path: '/manage/theme', // individual
				name: 'ManageTheme',
				component: ManageTheme,
				meta: {
					//seniority: 60,
					feature: 'theme',
					subfeature: null,
				},
				children: [
					{
						path: 'style',
						name: 'ManageThemeStyle',
						component: ManageThemeStyle,
						meta: {
							//seniority: 30,
							feature: 'theme',
							subfeature: 'style',
						},
					},
					{
						path: 'font',
						name: 'ManageThemeFont',
						component: ManageThemeFont,
						meta: {
							//seniority: 30,
							feature: 'theme',
							subfeature: 'font',
						},
					},
					{
						path: 'colour',
						name: 'ManageThemeColour',
						component: ManageThemeColour,
						meta: {
							//seniority: 30,
							feature: 'theme',
							subfeature: 'colour',
						},
					},
					{
						path: 'terminology',
						name: 'ManageThemeTerminology',
						component: ManageThemeTerminology,
						meta: {
							//seniority: 30,
							feature: 'theme',
							subfeature: 'terminology',
						},
					},
				],
			},
		]
	},
	{ // page not found
		path: '/:pathMatch(.*)*',
		name: 'Error404',
		component: Error404,
		meta: {},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
/*	scrollBehavior() {
		// always scroll to top
//		return {
//			top: 0,
//		}
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve({ top: 0 });
			}, 600);
		});
	},*/
});

// navigation guards
router.beforeEach( async (to, from, next) => {
	// initiate gui if not already done
	if (!store.state.gui.orgInitiated) {
		await store.dispatch('gui/initOrganisation');		
	}
	
	if (!store.state.auth.checked) {
		// auth status unset, so check if user is logged in
		await store.dispatch('auth/status');
		
	}
	
	// check requested route requires user to be logged in
	const requireLogin = to.matched.some(record => record.meta.requireLogin);
	
	if (to.name !== 'Signin' && requireLogin && !store.state.auth.loggedIn) {
		next({name:'Signin'});
		
	} else if (((to.name === 'Signin' && !to.params.signout) || to.name === 'Signup') && store.state.auth.loggedIn) {
		next({name:'Dashboard'});
		
//	} else if (store.state.auth.loggedIn && to.meta.seniority && store.getters['auth/getUserSeniority'] > to.meta.seniority) {	
	} else if (store.state.auth.loggedIn && to.meta.feature && !store.getters['gui/hasFeature'](to.meta.feature, to.meta.subfeature)) {
		// user role doesn't have feature/subfeature access
		next({name:'Error404'});
		
	} else {
		next();
	}
});

export default router;
