<template lang="pug">
include ./pug/svg
link(rel="stylesheet" :href="googleFontUrl")
link(rel="stylesheet" href="/css/index2.css" media="all")

router-view(v-slot="{Component}")
	transition(name="hide" mode="out-in" enter-active-class="hide" leave-active-class="hide")
		component(:is="Component" :style="cssVars" :class="cssClasses")
</template>
<script>
export default {
	name: 'App',
	data() {
		return {
		}
	},
	computed: {
		cssClasses() {
			// add css classes to all root components
			return this.$store.getters['gui/cssClasses'].join(' ');
		},
		cssVars() {
			// loads css vars into all components
			return this.$store.getters['gui/cssVars'];
		},
		googleFontUrl() {
			// load google font url
			const icon = this.$store.state.gui.icon;
			return this.$store.getters['gui/googleFontUrl'] + '&family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@'+icon.opsz+','+icon.wght+','+icon.fill+','+icon.grad;
		},
	},
}
</script>

<style lang="scss">
</style>
