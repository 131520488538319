<template lang="pug">
include ../pug/svg
footer &copy; ThinkHub {{year}}.
	a(href="https://redbot.uk" target="blank") &nbsp;Built by
		span REDBOT
			+svg(svg-filename="logoRedbot" aria-hidden="true" alt="REDBOT logo")
</template>

<script>
export default {
	name: 'Copyright',
	computed: {
		year() {
			const d = new Date();
			return d.getFullYear();
		},
	},
}
</script>

<style lang="scss">
</style>
