<template lang="pug">
include ../pug/svg
main#view
	h1 Recover
</template>

<script>
export default {
	name: 'Recover',
/*
main
	div.form(:class="{step2:identified}")
		Form(:validation-schema="schemaRecover" v-slot="{ errors, isSubmitting, values, meta }" @submit="doRecovery")
			h1 Recover your account
			p
				span.err(v-if="errors.email") {{ errors.email }}
				span(v-else) Let's identify you&hellip;
			div.field.req
				Field#email(name="email" type="email" placeholder="Unsupplied" autocomplete="on")
				label(for='email') Email Address
			span.req(aria-hidden="true") Required
			button.but.pri.spinner(:class="{'spin':isSubmitting}" :disabled="!values.email||!meta.valid||isSubmitting" type="submit") Recover Account
			router-link.but.sec(:to="{name:'Signin'}") Cancel


	setup() {
		const schemaIdentify = yup.object().shape({
			email: yup.string().email("Oops, that's an invalid email!"),
		});

		const schemaSignin = yup.object().shape({
			password: yup.string().required('Required'),
		});

		return {
			schemaIdentify,
			schemaSignin,
		}
	},
*/
}
</script>

<style lang="scss">
</style>
