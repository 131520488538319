// course data service
import httpApi from '../httpApi';

class CourseService {
	// api calls
	getCourses() {
		return httpApi.get('/courses');
	}

	createCourse(data) {
		return httpApi.put('/courses', data);
	}

	updateCourse(data) {
		return httpApi.post('/courses', { course: data });
	}
	
	archiveCourse(data) {
		return httpApi.post('/courses/archive', data);
	}
//	getCourseActivity(id) {
//		return httpApi.get(`/courses/activity/${id}`);
//	}
	
	createCourseActivity(data) {
		return httpApi.put('/courses/activity', data);
	}
	
	updateCourseActivity(data) {
		return httpApi.post('/courses/activity', data);
	}
	
	deleteCourseActivity(data) {
		return httpApi.patch('/courses/activity', data);
	}
	
	deleteAllCourseActivity(courseId) {
		return httpApi.delete('/courses/activity/'+courseId);
	}
//	get(id) {
//		return httpApi.get(`/courses/${id}`);
//	}
}

export default new CourseService();
