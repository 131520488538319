<template lang="pug">
include ../pug/svg
div#front(v-if="course" :class="{index:showIndex,tint:tintState}")
	aside
		//-router-link.exit(:to="{name:'Learn', params:{state:'active'}}")
			//-span Exit to Active {{terminology('course','course','plural')}}
			span Exit to Thinkhub
			+svg(svg-filename="iconMortarboard" aria-hidden="true" alt="Learn icon")
		router-link.exit(:to="{name:exitRoute.to, params:exitRoute.params}")
			span Exit to Thinkhub
			+svg(svg-filename="iconMortarboard" aria-hidden="true" alt="Learn icon")
			
		h4 {{terminology('course','course','singular')}} Progress
			//-router-link(:to="{name:'CourseSynopsis'}") {{terminology('course','course','singular')}} Progress
		div.progressbar
			span(:style="'width:'+progressPct+'%;'")
			|{{progressPct}}% Complete
		dl(:class="{noreturn:!course.lessonAllowReturn}")
			template(v-for="(section, i) in course.courseSections" :index="i" :key="section.id" tag="li")
				dt(:class="{shut:shutSections.includes(section.id)}" @click="toggleSection(section.id)")
					+svg(svg-filename="iconArrow" aria-hidden="true")
					span {{section.name}}
				dd
					ol
						template(v-for="(lesson, z) in course.courseLessons" :index="z" :key="lesson.id" tag="li")
							li(v-if="lesson.courseSectionId==section.id" :class="{complete:isComplete(lesson.id), prevent:preventAccess(lesson), current:isCurrent(lesson)}")
								+svg(svg-filename="iconTick" aria-hidden="true")
								a(v-if="preventAccess(lesson)") {{lesson.name}}
								router-link(v-else :to="{name:'CourseProgress', params:{lessonId:lesson.id}}") {{lesson.name}}
								span {{lesson.type}}
	main
		header
			//-button.but(type="button" @click="toggleIndex()")
				span Toggle Lesson Guide
				+svg(svg-filename="iconMenu" aria-hidden="true" alt="Menu icon")
			h1 {{course.name}}
			span
				img(:src="logoWhiteoutUrl" :alt="org.name")
		//-pre userCourseRole: {{userCourseRole}}
		//-pre courseUsers: {{course.courseUsers}}
		router-view(v-slot="{Component, route}")
			transition(name="course" mode="out-in" enter-active-class="hide" leave-active-class="hide" )
				component(:is="Component" :key="route.path" :course="course" :lesson="curlesson" :lessonProgress="lessonProgress" @logCourseActivity="logCourseActivity($event)" @preventProgress="preventProgress($event)" @toggleIndex="toggleIndex()")
		p.nav
			span.notice(v-if="incompleteLessonTasks") Please complete all on page tasks to proceed.
			button.but.pri.index(v-if="!curlesson" @click="toggleIndex()")
				+svg(svg-filename="iconMenu" aria-hidden="true")
				|Index
			router-link.but.pri.prev(v-if="prevLesson&&course.lessonAllowReturn" :to="{name:'CourseProgress', params:{lessonId:prevLesson.id}}")
				+svg(svg-filename="iconArrow" aria-hidden="true")
				|Back
			router-link.but.pri.next(v-if="nextLesson" :to="{name:'CourseProgress', params:{lessonId:nextLesson.id}}" :class="{disabled:incompleteLessonTasks}")
				|{{(!curlesson)?'Start':'Next'}}
				+svg(svg-filename="iconArrow" aria-hidden="true")
			button.but.pri.index(v-if="!nextLesson" @click="finishCourse(false)" :class="{disabled:preventFinish}" :disabled="preventFinish")
				+svg(svg-filename="iconMortarboard" aria-hidden="true")
				|Finish
			button.but.pri.index(v-if="course.courseAllowRetake&&!nextLesson&&!result" @click="finishCourse(true)" :class="{disabled:preventFinish}" :disabled="preventFinish")
				+svg(svg-filename="iconRetry" aria-hidden="true")
				|Resit
			button.but.sec.index(v-if="userCourseRole.seniority<=40" @click="binCourseActivity()" :class="{disabled:!canBinCourseActivity}" :disabled="!canBinCourseActivity")
				+svg(svg-filename="iconBin" aria-hidden="true")
				|Clear Data
		//-pre userCourseRole: {{userCourseRole}}
		//-pre courseUserActivity: {{courseUserActivity}}
		footer
</template>

<script>
//import { Form, Field, ErrorMessage } from 'vee-validate';
//import * as Yup from 'yup';

//import OrganisationService from '../services/OrganisationService';
import CourseService from '../services/CourseService';
//import { nextTick } from 'vue';
export default {
	name: 'Course',
	components: {
	},
	data() {
		return {
			courseId: parseInt(this.$route.params.courseId),
			showIndex: false,
			shutSections: [],
			preventNext: [],
			result: null,
		}
	},
	computed: {
		tintState() {
			return this.$store.state.gui.tintState;
		},
		org() {
			return this.$store.state.gui.organisation;
		},
		user() {
			return this.$store.state.auth.user;
		},
		exitRoute() {
			const role = this.userCourseRole;
			
			let route = {
				to: 'ManageLearningCourseLesson',
				params: {
					courseId : this.courseId,
					lessonId : this.lessonId
				},
			};
			
			if (role.slug == 'learner') {
				route.to = 'Learn';
				route.params.state = this.courseState;
				
			} else if (role.slug == 'mentor') {
				route.to = 'ManageLearningCourseActivity';
			}
			
			return route;
		},
		userCourseRole() {
			return this.$store.getters['courses/getUserCourseRole'](this.courseId);
		},
		courseState() {
			const completedAttempt = this.course.courseUserActivities.find(a => a.userId == this.user.id && a.activityType == 'course' && a.activitySubtype == 'completed') || null;
			return completedAttempt ? 'completed' : 'active';
		},
		logoWhiteoutUrl() {
			return this.org.logoWhiteoutUrl ? this.org.logoWhiteoutUrl : '/img/logo-thinkhub-short-wo.svg';			
		},
		course() {
			return this.$store.getters['courses/getCourse'](this.courseId);
		},
		lessonId() {
			return parseInt(this.$route.params.lessonId);
		},
		curlesson() {
			return (this.course.courseLessons) ? this.course.courseLessons.find(l => l.id == this.lessonId) : null;
		},
		prevLesson() {
			let index = this.lessonIndex(this.lessonId);
			index --;
			return (index >= 0) ? this.course.courseLessons[index] : null;
		},
		nextLesson() {
			let index = this.lessonIndex(this.lessonId);
			index ++;
			return (index < this.course.courseLessons.length) ? this.course.courseLessons[index] : null;
		},
		progressPct() {
			const completedLessons = this.courseProgress.filter(a => a.activityType == 'lesson' && a.activitySubtype == 'completed');
			//const pct = ((this.completedLessons.length / this.course.courseLessons.length) * 100);
			const pct = ((completedLessons.length / this.course.courseLessons.length) * 100);
			return Math.round((pct + Number.EPSILON) * 100) / 100;
		},
		courseUserActivity() {
			// copy store to local (allows list sorting)
			let activities = [...this.course.courseUserActivities].filter(a => a.userId == this.user.id);
			// sort by id desc (most recent first)
			activities.sort((a,b) => b.id - a.id);

			return activities;
		},
		canBinCourseActivity() {
			if (!this.courseUserActivity.length) {
				return false;	
			}
			
			const lastActivity = this.courseUserActivity[0];
			
			return (lastActivity.lessonId == this.lessonId && lastActivity.activityType == 'lesson' && lastActivity.activitySubtype == 'started') ? false : true;
		},
		attempt() {
			// flag user attempts
			const activities = this.courseUserActivity;
			let attempt = 1;

			if (activities.length) {
				// find last activity
				const last = activities.find(a => (a.activityType == 'course' && a.activitySubtype == 'completed') || (a.activityType == 'lesson' && a.activitySubtype == 'started') || (a.activityType == 'course' && a.activitySubtype == 'started'));

				if (last.activityType == 'course' && last.activitySubtype == 'completed') {
					// finished course, so start new attempt
					attempt = last.attempt + 1;
				} else {
					// unfinished, so reuse the attempt
					attempt = last.attempt;
				}
			}

			return attempt;
		},
		preventFinish() {
			const inCompletedLessons = this.course.courseLessons.filter(l => !this.isComplete(l.id));
console.log('inCompletedLessons;', inCompletedLessons);
			if (this.incompleteCourseTasks || inCompletedLessons.length > 1) {
				// additional lesson or incomplete tasks
				return true;
				
			} else if (inCompletedLessons.length == 1 && inCompletedLessons[0].id != this.lessonId) {
				// last incomplete lesson
				return true;
			}
			
			return false;
		},
		courseProgress() {
			let courseProgress = [];
			for (const a of this.courseUserActivity) {
				if (a.attempt == this.attempt) {
					let data = {
						id: a.id,
//						courseId: parseInt(a.courseId),
						lessonId: parseInt(a.lessonId) || null,
						activityType: a.activityType,
						activitySubtype: a.activitySubtype,
						attempt: a.attempt,
					};

					if (a.activityType == 'task') {
						data.taskQuestion = a.activityData.taskQuestion;
						data.taskResult = a.activityData.taskResult;
						
						if (a.activitySubtype == 'checkbox' || a.activitySubtype == 'radio') {
							data.userAnswers = [];
							
							for (let i of a.activityData.userAnswers) {
								data.userAnswers.push({
									option: a.activityData.taskAnswers[i].option,
								});
							}
							
						} else if (a.activitySubtype == 'freeform') {
//							data.userAnswers.push(a.activityData.userAnswers[0]); // change from array??
							data.userAnswer = a.activityData.userAnswer;
						}
					}
					
					courseProgress.push(data);
				}
			}
			
			return courseProgress;
		},
		lessonProgress() {
			return this.courseProgress.filter(a => a.lessonId == this.lessonId);
		},
		incompleteCourseTasks() {
			return (this.preventNext.length) ? true : false;
		},
		incompleteLessonTasks() {
			// current lesson
			return (this.preventNext.findIndex(o => o.lessonId == this.lessonId) !== -1);
		},
		defaultLesson() {
			const startedLessons = this.courseProgress.filter(a => a.activityType == 'lesson' && a.activitySubtype == 'started');
			return (startedLessons.length) ? startedLessons[0] : this.course.courseLessons[0];
		},
	},
	methods: {
		async mount() {
			await this.getCourse();
			
			if (this.userCourseRole.slug == 'learner' && !this.course.courseAllowRetake && this.attempt > 1) {
				// prevent learner resit
				let params = this.exitRoute.params;
				params.state = 'completed';
				
				this.$router.push({
					name: this.exitRoute.to, 
					params,
				});
				
				return false; // required				
			}
			
			const lessonId = parseInt(this.$route.params.lessonId) || null;
			const defaultLessonId = ('lessonId' in this.defaultLesson) ? this.defaultLesson.lessonId : this.defaultLesson.id;
			
			if (!this.courseProgress.length) {
				// log course as started
				await this.logCourseActivity({
					courseId: this.course.id,
					lessonId: null,
					activityType: 'course',
					activitySubtype: 'started',
					activityData: {
						logTitle: 'Course started',
						logMessage: 'User started course: '+this.course.name,
						courseName: this.course.name,
						examMode: this.course.examMode,
						examPassScore: this.course.examPassScore,
					}
				});
				
				// log lesson as started
				await this.logCourseActivity({
					courseId: this.course.id,
					lessonId: defaultLessonId,
					activityType: 'lesson',
					activitySubtype: 'started',
					activityData: {
						logTitle: 'Lesson started',
						logMessage: 'User started lesson: '+this.defaultLesson.name,
						lessonName: this.defaultLesson.name,
					}
				});
			}
			
			if (lessonId != defaultLessonId) {
				// trying to enter at wrong point
				this.$router.push({
					name:'CourseProgress',
					params: {
						courseId: this.courseId,
						lessonId: defaultLessonId,
						mounted: 1, // flag regdirect from initial mount
					}
				});	
			}
		},
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		toggleIndex() {
			this.showIndex=!this.showIndex;
		},
		async getCourse() {
			// load courses from db
			await this.$store.dispatch('courses/dbCourses');
		},
		async binCourseActivity() {
			try {
				if (!this.canBinCourseActivity) {
					return false;
				}
				
				// bin current lesson task and following lesson data
				let data = {
					courseId: this.course.id,
					lessonId: this.curlesson.id,
					attempt: this.attempt,
				}
				
				await CourseService.deleteCourseActivity(data);
				
				// reload cuurent lesson
				//this.$router.go(0);
				
				if (this.prevLesson) {
					this.$router.push({
						name:'CourseProgress', 
						params:{courseId: this.course.id, lessonId: this.prevLesson.id},
					});
					
				} else {
					// exit to thinkhub
					this.$router.push({
						name: this.exitRoute.to, 
						params: this.exitRoute.params,
					});					
				}
				
			} catch (error) {
				console.log('logCourseActivity:error', error);
			}
		},
		async logCourseActivity(activityLog) {
			try {
				activityLog.attempt = this.attempt;
				activityLog.preview = (this.userCourseRole.slug !== 'learner');
				
				const al = activityLog;
				let index;
				
				if (al.activityType == 'task') {
					// check if task was completed
					index = this.courseProgress.findIndex(a => a.lessonId == al.lessonId  && a.activityType == al.activityType && a.activitySubtype == al.activitySubtype && a.attempt == al.attempt && a.taskQuestion == al.activityData.taskQuestion);

				} else {
					index = this.courseProgress.findIndex(a => a.lessonId == al.lessonId  && a.activityType == al.activityType && a.activitySubtype == al.activitySubtype && a.attempt == al.attempt);
				}

				if (index >= 0) {
					// already logged
					return false;					
				}

				// save log to db
console.log('activityLog:', activityLog);
				await CourseService.createCourseActivity(activityLog);
				// update store
				this.getCourse();

			} catch (error) {
				console.log('thrown from courseCtrl:', error);
			}
		},
		preventAccess(lesson) {
			// find first incomplete lesson
			const lessonIndex = this.lessonIndex(lesson.id); // lesson checking
			let prevent = false;

if (!this.course.lessonChronological) {
	return prevent;
}
//console.log('preventAccess:', lesson.id);
			for (let index in this.course.courseLessons) {
				const complete = this.isComplete(this.course.courseLessons[index].id);
//console.log('index:', index, this.course.courseLessons[index].id, complete);
//				if (complete && !this.course.lessonAllowReturn) {
//					prevent = true;
//					break;
//				}
				
				if (!complete && lessonIndex > index) {
					prevent = true;
					break;
				}
			}

			return prevent;
		},
		lessonIndex(lessonId) {
			return this.course.courseLessons.findIndex(l => l.id == parseInt(lessonId));
		},
		toggleSection(sectionId) {
			const index = this.shutSections.findIndex(id => id == sectionId);

			if (index >= 0) {
				this.shutSections.splice(index, 1);
			} else {
				this.shutSections.push(sectionId);
			}
		},
		preventProgress(payload) {
			const index = this.preventNext.findIndex(o => o.blockId == payload.blockId);
			
			if (payload.bool && index === -1) {
				// add incomplete task
console.log('preventProgress:push', payload);
				this.preventNext.push(payload);
				
			} else if (!payload.bool && index >= 0) {
				// remove completed task
console.log('preventProgress:splice', payload);
				this.preventNext.splice(index, 1);
			}
		},
		isCurrent(lesson) {
			return (lesson.id == this.lessonId);
		},
		isStarted(lessonId) {
			const index = this.courseProgress.findIndex(a => a.activityType == 'lesson' && a.activitySubtype == 'started' && a.lessonId == lessonId);
			return (index >= 0);
		},
		isComplete(lessonId) {
			const index = this.courseProgress.findIndex(a => a.activityType == 'lesson' && a.activitySubtype == 'completed' && a.lessonId == lessonId);
			return (index >= 0);
		},
		lessonCompletionTime(lessonId) {
			const s = this.courseUserActivity.find(a => a.lessonId == lessonId && a.attempt == this.attempt && a.activityType == 'lesson' && a.activitySubtype == 'started');
			const e = this.courseUserActivity.find(a => a.lessonId == lessonId && a.attempt == this.attempt && a.activityType == 'lesson' && a.activitySubtype == 'completed');
			const startDate = new Date(s.activityDatetime);
			const endDate = new Date(e.activityDatetime);
			const secs = ( endDate.getTime() - startDate.getTime() ) / 1000;

			return secs;
		},
		async finishCourse(resit) {
			try {
				if (this.preventFinish) {
					return false;
				}
				
				if (this.userCourseRole.slug == 'learner') {
					if (!this.isComplete(this.curlesson.id)) { // NEW
						await this.logCourseActivity({
							courseId: this.course.id,
							lessonId: this.curlesson.id,
							activityType: 'lesson',
							activitySubtype: 'completed',
							activityData: {
								logTitle: 'Lesson completed',
								logMessage: 'User completed lesson: '+this.curlesson.name,
								lessonName: this.curlesson.name,
		//						completionTime: this.lessonCompletionTime(this.curlesson.id),
							}
						});
					}
					
					await this.logCourseActivity({
						courseId: this.course.id,
						lessonId: null,
						activityType: 'course',
						activitySubtype: 'completed',
						activityData: {
							logTitle: 'Course completed',
							logMessage: 'User completed course: '+this.course.name,
							courseName: this.course.name,
						}
					});
					
				} else {
					// bin all preview data for user
					await CourseService.deleteAllCourseActivity(this.courseId);	
				}

				if (resit && this.course.courseAllowRetake) {
					await this.mount();
					
				} else {
					let params = this.exitRoute.params;
					
					if (this.userCourseRole.slug == 'learner') {	
						params.state = 'completed';
					}
					
					this.$router.push({
						name: this.exitRoute.to, 
						params,
					});					
				}
				
			} catch (error) {
				console.log('thrown from courseCtrl:', error);
			}
		},
	},
	/*beforeRouteEnter(to, from, next) {
		console.log('beforeRouteEnter:to.params:',to.params);
		if (!to.params.lessonId) {
			// synopsis
			next();
		} else {
			// if there is an unfinished attempt redirect to first incomplete lesson
			// else redirect to synopsis
			next('/'+to.params.courseId+'/synopsis');
//			next();
		}
	},*/
	beforeRouteEnter(to, from, next) {
		console.log('beforeRouteEnter:from:',from.params.lessonId, from.name);
		console.log('beforeRouteEnter:to:',to.params.lessonId, to.name);
		next();
	},
	async beforeRouteUpdate(to, from, next) {
		console.log('beforeRouteUpdate:from:',from.params.lessonId, from.name);
		console.log('beforeRouteUpdate:to:',to.params.lessonId, to.name);
		console.log('preventNext:', this.incompleteCourseTasks, this.preventNext);
		
		if (to.params.mounted == 1) {
			// redirected on mount due to entering at wrong point
			console.log('mounted redirect');
			next();
			return;
		}
		
		// before switch between lessons or synopsis (within course)
		const fromIndex = this.lessonIndex(from.params.lessonId);
		const toIndex = this.lessonIndex(to.params.lessonId);
		let allow = null;
//console.log('fromIndex:', fromIndex);
//console.log('toIndex:', toIndex);

		if (toIndex < fromIndex) {
//console.log('COURSE::', toIndex, fromIndex);
			// previous lesson request
			if (!this.course.lessonAllowReturn) {
//console.log('COURSE:lessonAllowReturn:', this.course.lessonAllowReturn);				
				if (this.isComplete(to.params.lessonId)) {
					// prevent revisit of previously completed lesson ??
console.log('COURSE:a)prevent revisit of previously completed lesson:');
					allow = false;
				} else {
					// allow revisit of previously completed lesson
console.log('COURSE:b)allow revisit of previous uncompleted lesson:', to.params.lessonId, this.isComplete(to.params.lessonId));
					allow = true;
				}
				
			} else {
				// allow revisit
console.log('COURSE:allow revisit of previous lesson');
				allow = true;
			}
			
		} else if (this.incompleteLessonTasks) {
			// enforce task completion
console.log('COURSE:enforce task completion');
			allow = false;

		} else if (this.course.lessonChronological == 1 && Math.abs(toIndex - fromIndex) > 1 && !this.isComplete(to.params.lessonId) && to.params.lessonId != this.defaultLesson.lessonId) {
			// enforce chronological lesson order
console.log('COURSE:enforce chronological lesson order', to.params.lessonId, this.defaultLesson);
			allow = false;
			
		} else {
			allow = true;
		}
		
		if (allow == true) {
//console.log('from.params.lessonId:', from.params.lessonId );
//console.log('to.params.lessonId:', to.params.lessonId );
console.log('-------------');
console.log('beforeRouteUpdate:from:',from.params.lessonId, from.name);
console.log('beforeRouteUpdate:to:',to.params.lessonId, to.name);

			
console.log('from:isComplete:', from.params.lessonId, this.isComplete(from.params.lessonId) );
console.log('to:isStarted:', to.params.lessonId, this.isStarted(to.params.lessonId) );
console.log('preventNext:', this.incompleteCourseTasks, this.preventNext);
			/*if (toIndex > fromIndex) {
				if ((from.params.lessonId)) {
					// log previous lesson as completed
					const fromLesson = this.course.courseLessons[fromIndex];

					await this.logCourseActivity({
						courseId: from.params.courseId,
						lessonId: from.params.lessonId,
						activityType: 'lesson',
						activitySubtype: 'completed',
						activityData: {
							logTitle: 'Lesson completed',
							logMessage: 'User completed lesson: '+fromLesson.name,
							lessonName: fromLesson.name,
//							completionTime: this.lessonCompletionTime(from.params.lessonId),
						}
					});
				}

				if ((to.params.lessonId)) {
					// log next lesson as started
					const toLesson = this.course.courseLessons[toIndex];

					await this.logCourseActivity({
						courseId: to.params.courseId,
						lessonId: to.params.lessonId,
						activityType: 'lesson',
						activitySubtype: 'started',
						activityData: {
							logTitle: 'Lesson started',
							logMessage: 'User started lesson: '+toLesson.name,
							lessonName: toLesson.name,
						}
					});
				}
			}*/
			if (from.params.lessonId && this.isStarted(from.params.lessonId) && !this.isComplete(from.params.lessonId) && !this.incompleteCourseTasks ) {
				
				if (this.course.lessonChronological == 0 || (this.course.lessonChronological == 1 && toIndex > fromIndex)) {
					// log previous lesson as completed
					const fromLesson = this.course.courseLessons[fromIndex];

					await this.logCourseActivity({
						courseId: from.params.courseId,
						lessonId: from.params.lessonId,
						activityType: 'lesson',
						activitySubtype: 'completed',
						activityData: {
							logTitle: 'Lesson completed',
							logMessage: 'User completed lesson: '+fromLesson.name,
							lessonName: fromLesson.name,
	//							completionTime: this.lessonCompletionTime(from.params.lessonId),
						}
					});
				}
			}
			
			if (to.params.lessonId && !this.isStarted(to.params.lessonId)) {
				// log next lesson as started
				const toLesson = this.course.courseLessons[toIndex];
				
				await this.logCourseActivity({
					courseId: to.params.courseId,
					lessonId: to.params.lessonId,
					activityType: 'lesson',
					activitySubtype: 'started',
					activityData: {
						logTitle: 'Lesson started',
						logMessage: 'User started lesson: '+toLesson.name,
						lessonName: toLesson.name,
					}
				});
			}
			
			// proceed
			this.preventNext = [];
			next();
			
		} else {
console.log('-------------');
console.log('PREVENT');
			next(false);
		}
	},
	/*beforeRouteLeave(to, from, next) {
		// is course complete?
		//console.log('COURSE:beforeRouteLeave():',to);
		next();
	},*/
	async mounted() {
		this.mount();
	},
}
</script>

<style lang="scss">
body {
	overflow-x: hidden;
	overflow-y: auto;
}
#front {
	font-family: var(--bod-fam);
	color: var(--bod-col);
	background-color: var(--bod-bg);	
	h1 {
		font-family: var(--h1-fam);
		font-weight: var(--h1-wgt);
		text-transform: var(--h1-uc);
	}
	h3 {
		font-family: var(--hdg-fam);
		font-weight: var(--hdg-wgt);
		text-transform: var(--hdg-uc);
	}
	h4 {
		font-family: var(--sub-fam);
		font-weight: var(--sub-wgt);
		text-transform: var(--sub-uc);
	}
	input,
	select,
	button,
	textarea {
		font-family: var(--bod-fam);
		font-weight: var(--bod-wgt);
	}
	
	.but {
		cursor: pointer;
		transition: all 0.3s ease-in-out;
		background: none;
		border: 1px solid transparent;
		white-space: nowrap;
		font-size: 1.2rem;
		font-weight: 500;
		text-transform: var(--lab-uc);	
		border-radius: var(--but-rad);
	}
	.pri,
	.sec {
		justify-content: center;
		min-width: 70px;
		padding: 0 12px;
	}
	&.pri-solid .pri {
		color: var(--pri-col);
		background: var(--pri-bg);
		.fill {
			fill: var(--pri-col);
		}
		&:hover {
			color: var(--pri-col-hvr);
			background: var(--pri-bg-hvr);
			.fill {
				fill: var(--pri-col-hvr);
			}
		}
		&.disabled {
			cursor: not-allowed;
			color: var(--pri-col-dis);
			background: var(--pri-bg-dis);
			.fill {
				fill: var(--pri-col-dis);
			}
		}
	}	
	&.pri-outline .pri {
		color: var(--pri-col);
		border: 1px solid var(--pri-col);
		.fill {
			fill: var(--pri-col);
		}
		&:hover {
			color: var(--pri-col-hvr);
			border-color: var(--pri-bg-hvr);
			background: var(--pri-bg-hvr);
			.fill {
				fill: var(--pri-col-hvr);
			}
		}
		&.disabled {
			cursor: not-allowed;
			color: var(--pri-col-dis);
			background: var(--pri-bg-dis);
			.fill {
				fill: var(--pri-col-dis);
			}
		}
	}
	&.sec-solid .sec {
		color: var(--sec-col);
		background: var(--sec-bg);
		.fill {
			fill: var(--sec-col);
		}
		&:hover {
			color: var(--sec-col-hvr);
			background: var(--sec-bg-hvr);
			.fill {
				fill: var(--sec-col-hvr);
			}
		}
		&.disabled {
			cursor: not-allowed;
			color: var(--sec-col-dis);
			background: var(--sec-bg-dis);
			.fill {
				fill: var(--sec-col-dis);
			}
		}
	}	
	&.sec-outline .sec {
		color: var(--sec-col);
		border: 1px solid var(--sec-col);
		.fill {
			fill: var(--sec-col);
		}
		&:hover {
			color: var(--sec-col-hvr);
			border-color: var(--sec-bg-hvr);
			background: var(--sec-bg-hvr);
			.fill {
				fill: var(--sec-col-hvr);
			}
		}
		&.disabled {
			cursor: not-allowed;
			color: var(--sec-col-dis);
			background: var(--sec-bg-dis);
			border-color: transparent;
			.fill {
				fill: var(--sec-col-dis);
			}
		}
	}
	&:before {
		z-index: 111;
		content: '';
		pointer-events: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: rgba(#000, .2);
		//transition: opacity 0.3s 0s ease-in-out, visibility 0s 0s ease-in-out;
		transition: opacity .3s 0s ease-in-out;
		opacity: 0;
		visibility: visible;
	}
	&.tint {
		&:before {
			opacity: 1;
			visibility: visible;
		}
	}
	&.index {
		aside {
			opacity: 1;
			transition-delay: 0s;
		}
		main {
			transform: translateX(280px);
			&:before {
				opacity: .2;
			}
		}
	}
	aside { // menu
		z-index: 0;
		position: fixed;
		top: 0;
		left: 0;
		width: 280px;
		height: 100%;
		padding: 20px;
		overflow-y: scroll;
		overflow-x: hidden;
		opacity: 0;
		transition: opacity 0s .3s ease-in-out;
		a.exit { // exit header
			display: flex;
			align-items: center;
			line-height: 1;
			min-height: 50px;
			margin: -20px -20px 20px -20px;
			padding: 15px;
			font-size: 1.4rem;
			color: var(--pan-bar-col);
			background: var(--pan-bar-bg);
			span {
				transition: .3s ease-in-out;
			}
			svg {
				order: -1;
				margin-right: 10px;
			}
			&:hover {
				>* {
					opacity: .5;
				}
			}
		}
		h4 {
			font-size: 1.6rem;
		}
		.progressbar {
//			position: sticky;
//			top: 0;
			position: relative;
			margin-bottom: 20px;
			font-size: 1rem;
			text-transform: uppercase;
			text-align: right;
			&:before,
			span { // progress
				display: block;
				height: 4px;
				border-radius: 2px;
				background: var(--num-bg);
			}
			&:before { // bar
				content: '';
				background: var(--num-bg-dis);
			}
			span {
				width: 0;
				margin: -4px 0 5px 0;
			}
		}
		dt {
			cursor: pointer;
			display: flex;
			align-items: center;
			width: calc(100% + 20px);
			transform: translateX(-20px);
			min-height: 30px;
			margin-bottom: 20px;
			padding: 10px 20px;
			font-size: 1rem;
			font-family: var(--lab-fam);
			font-weight: var(--lab-wgt);
			border-top-right-radius: var(--box-rad);
			border-bottom-right-radius: var(--box-rad);
			text-transform: var(--lab-uc);
			color: var(--pan-bar-col);
			background: var(--pan-bar-bg);
			>span {
				transition: .3s ease-in-out;
			}
			>svg {
				order: 1;
				margin-left: auto;
				.fill {
					fill: var(--pan-bar-col);
				}
			}
			&:hover {
				>* {
					opacity: .5;
				}
			}
			&.shut {
				>svg {
					transform: rotate(180deg);
				}
				+dd {
					height: 0;
				}
			}
		}
		dd {
			overflow: hidden;
		}
		ol {
			padding-bottom: 10px;
		}
		li {
			position: relative;
			list-style-type: none;
			padding-left: 30px;
			&:before { // node
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				border: 2px solid var(--pri-bg);
				transition: background .3s ease-in-out;
			}
			&:not(:last-child):after { // node line
				content: '';
				position: absolute;
				top: 20px;
				left: 9px;
				bottom: 0;
				width: 2px;
				background: var(--pri-bg);
			}
			svg { // tick
				position: absolute;
				top: 5px;
				left: 5px;
				width: 10px;
				height: 10px;
				opacity: 0;
				transition: opacity .3s ease-in-out;
				.fill {
					fill: var(--pri-col);
				}
			}
			span {
				display: flex;
				align-items: center;
				padding-bottom: 20px;
				min-height: 21px;
				font-size: 1rem;
				color: var(--lab-col);
				font-family: var(--lab-fam);
				font-weight: var(--lab-wgt);
				text-transform: var(--lab-uc);
			}
			a {
				display: inline-block;
				cursor: pointer;
				font-size: 1.4rem;
				color: var(--nav-col-on);
				&:not([href]) {
					cursor: not-allowed;
				}
			}
			&.prevent {
				a,
				span {
					color: var(--nav-col-dis);
				}
			}
			&.current {
				.fill {
					fill: var(--pri-col);
				}
				&:before {
					background: var(--pri-bg);
				}
			}
			&.complete {
				svg {
					opacity: 1;
				}
				&:not(.current) {
					.fill {
						fill: var(--pri-bg);
					}
				}
			}
		}
		dl {
			&.noreturn {
				li.complete {
					pointer-events: none;
					a, span {
						pointer-events: auto;
						cursor: not-allowed;
						color: var(--nav-col-dis);
					}
				}
			}
		}
	}
	main {
		position: relative;
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		box-shadow: 0 0 10px rgba(#000, .2);
		transition: transform .3s ease-in-out;
		background: #FFF; // leave
/*		&:before { // tint?
			z-index: 4;
			pointer-events: none;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #000;
			opacity: 0;
			transition: opacity .3s ease-in-out;
		}*/
		>header {
			z-index: 3;
			display: flex;
			min-height: 70px;
			padding: 20px;
			background: var(--pri-bg);
			span { // thinkhub logo
				height: 40px;
				width: 100px;
				min-width: 100px;
				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
					object-position: left;
				}
			}
			h1 {
				flex-grow: 1;
//				font-size: 3rem;
				font-size: 2.6rem;
				align-self: center;
				margin: 0 20px 0 120px;
				text-align: center;
				color: var(--pri-col);
			}
		}
	}
	#progress {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		>h2 { // lesson title
			z-index: 3;
			position: sticky;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 50px;
			margin: 0;
			padding: 15px 120px;
			font-size: 1.4rem;
			font-family: var(--lab-fam);
			font-weight: var(--lab-wgt);
			text-transform: var(--lab-uc);
			color: var(--pan-bar-col);
			background: var(--pan-bar-bg);
			//box-shadow: 0 0 10px rgba(#000, .5);
			>span { // 1 of 7
				display: flex;
				align-items: center;
				margin-left: -10px;
			}
			.but { // menu toggle
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40px;
				height: 40px;
				.fill {
					fill: #FFF;
				}
				svg {
					width: 16px;
					height: 16px;
				}
				&:hover {
					opacity: .5;
				}
			}
		}
		section {
			&:only-of-type { // fill available height and center
				flex-grow: 1;
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
			}
		}
	}
	p.nav { // lesson navigation
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: 40px;
		.but {
			&:not(:last-child) {
				margin-right: 20px;
			}
			&.index {
				svg {
					margin-right: 10px;
				}
			}
			&.prev {
				svg {
					margin-right: 10px;
					transform: rotate(90deg);
				}
			}
			&.next {
				svg {
					margin-left: 10px;
					transform: rotate(-90deg);
				}
			}
		}
		.notice {
			position: absolute;
			bottom: 100%;
			left: 50%;
			transform: translateX(-50%);
			display: block;
			width: 100%;
			margin-bottom: 20px;
			text-align: center;
			color: var(--pri-bg);
		}
	}
	footer {
		padding: 0 20px 20px 20px;
		&:before {
			content: '';
			background: url(/img/logo-thinkhub-short-grey.svg) no-repeat;
			background-size: contain;
			display: block;
			width: 90px;
			height: 40px;
		}
	}
	#synopsis {
		flex-grow: 1;
	}
	@media only screen and (max-width: 580px) {
		main >header {
			flex-direction: column;
			align-items: center;
			h1 {
				margin: 0 !important;
			}
			span {
				order: -1;
				margin-bottom: 40px;
				margin-right: auto;
			}
		}
		#progress >h2 {
			align-items: flex-start;
			flex-direction: column;
			padding: 5px 20px 15px 20px;
		}
		footer:before {
			width: 100%;
			background-position: center;
		}
	}
	@media only screen and (max-width: 1024px) {
		main >header h1 {
			margin: 0 20px 0 0;
			text-align: left;
		}
	}
	@media only screen and (min-width: 581px) {
		#progress >h2 >span {
			position: absolute;
			top: 50%;
			left: 20px;
			transform: translateY(-50%);
		}
	}
}
#front {
	.ratio-fullheight::before {
		padding-top: 100vh;
	}
	.ratio-square:before {
		aspect-ratio:1/1;
	}
	.ratio-camera:before {
		aspect-ratio:3/2;
	}
	.ratio-classic:before {
		aspect-ratio:4/3;
	}
	.ratio-widescreen:before {
		aspect-ratio:16/9;
	}
	.ratio-cinema:before {
		aspect-ratio:21/9;
	}
	.block {
		&.pt-off {
			padding-top: 0 !important;
		}
		&.pb-off {
			padding-bottom: 0 !important;
		}
		@media only screen and (max-width: 899px) {
			padding: 40px 20px;
			&.pt-hlf {
				padding-top: 20px;
			}
			&.pb-hlf {
				padding-bottom: 20px;
			}
			&.pt-dbl {
				padding-top: 80px;
			}
			&.pb-dbl {
				padding-bottom: 80px;
			}
		}
		@media only screen and (min-width: 900px) {
			padding: 40px 80px;
			&.pt-hlf {
				padding-top: 20px;
			}
			&.pb-hlf {
				padding-bottom: 20px;
			}
			&.pt-dbl {
				padding-top: 80px;
			}
			&.pb-dbl {
				padding-bottom: 80px;
			}
		}
		&.fullwidth {
			padding: 0 !important;
		}
		&[class*=ratio] {
			display: flex;
			flex-wrap: wrap;
			&:before {
				pointer-events: none;
				margin-left: -100%;
			}
		}
		&.text,
		&.list, 
		&.task,
		&.withtext { // image
			h3 {
				font-size: 3.2rem;
				color: var(--hdg-col);
			}
			h4 {
				font-size: 1.6rem;
				color: var(--sub-col);
				&.first {
					order: -1;
					margin-bottom: 5px;
				}
				&:not(.first) {
					margin-top: -15px;
				}
			}
			p {
				line-height: 1.5;
			}
			&.whitetext {
				h3, h4, p, li {
					color: #FFF; // leave
				}
				li:before {
					color: #000 !important; // leave
					background: #FFF !important; // leave
				}
			}
			&[style*="background-color"] {
				+.divider {
					&:after {
						background: transparent;
					}
				}
			}
			&:not([style*="background-color"]) {
				+.text,
				+.list {
					&:not([style*="background-color"]) {
						padding-top: 0;
					}
				}
			}
		}
		&.text {
			&.txt-left {
				text-align: left;
				picture.icon {
					margin-right: auto;
				}				
			}
			&.txt-center {
				text-align: center;
				picture.icon {
					margin-left: auto;
					margin-right: auto;
				}				
			}
			&.txt-right {
				text-align: right;
				picture.icon {
					margin-left: auto;
				}				
			}
			>div {
				display: flex;
				flex-direction: column;
			}
			p a {
				color: var(--lnk-col);
				transition: .3s ease-in-out;
				&:hover {
					color: var(--lnk-col-hvr);				
				}
			}
		}
		&.list {
			>div {
				display: flex;
				justify-content: flex-start;
				>ol, ul {
					display: inline-flex;
					flex-direction: column;
					li {
						position: relative;
						display: flex;
						list-style-type: none;
						&:not(:last-child) {
							margin-bottom: 10px;
						}
					}
				}
			}
			&.txt-left >div {
				justify-content: flex-start;
			}
			&.txt-center >div {
				justify-content: center;
			}
			&.txt-right >div {
				justify-content: flex-end;
			}
			&.bulleted {
				ul {
					li {
						&:before { // bullet marker
							content: '';
							align-self: flex-start;
							transform: translateY(3px);
							margin-right: 10px;
							width: 12px;
							min-width: 12px;
							height: 12px;
							border-radius: var(--li-rad);
							background: var(--li-mkr-bg);
						}
					}
				}
			}
			&.numbered {
				ol {
					counter-reset: li;
					li {
						&:before { // number marker
							counter-increment: li;
							content: counter(li);
							align-self: flex-start;
							transform: translateY(-3px);
							margin-right: 10px;
							width: 24px;
							min-width: 24px;
							line-height: 24px;
							text-align: center;
							font-size: 1.2rem;
							font-weight: 500;
							border-radius: var(--li-rad);
							color: var(--li-mkr-col);
							background: var(--li-mkr-bg);
						}
					}
				}
			}
		}
		&.divider {
			&.numbered {
				position: relative;
				padding: 0;
				//min-height: 50px;
				header {
					z-index: 2;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					display: flex;
					align-items: center;
					justify-content: center;
					width: 50px;
					height: 50px;
					margin: auto;
					font-size: 2rem;
					font-weight: var(--hdg-wgt);
					border-radius: var(--hr-rad);
					color: var(--li-mkr-col);
					background: var(--li-mkr-bg);
//box-shadow: 0 4px 8px 0px rgba(#000, .2);
				}
				&:after { // horz line
					content: '';
					position: absolute;
					top: calc(50% + 1px);
					left: 50%;
					height: 1px;
					width: 100%;
					max-width: 900px;
					transform: translateX(-50%);
					background: var(--pan-hr);
				}
				+.block[style*="background-color"] {
					z-index: 1;
				}
			}
		}
		&.task {
			h3 {
				font-size: 2.4rem;
			}
			h4:not(.first) {
				margin-top: -10px;
			}
			p.points {
				margin-top: -10px;
				font-size: 1.4rem;
			}
			>div {
				&.submitted {
					.but.submit {
						pointer-events: none;
						color: transparent !important;
						border-color: var(--pri-bg-dis) !important;
						transition-duration: 0s;
						span {
							transform: translate(50%,50%);
							bottom: 50%;
							right: 50%;
							height: 20px;
							svg {
								height: 100%;
								.fill {
									fill: var(--pri-bg-dis) !important;
								}
							}
						}
					}
				}
			}
			&.radio,
			&.checkbox {
				position: relative;
				>div {
					&.submitted {
						li {
							cursor: not-allowed;
							&.selected {
								background: var(--pri-bg-dis) !important;
								border-color: var(--pri-bg-dis) !important;
							}
							&:not(.selected) {
								color: var(--pri-col-dis) !important;
								border-color: var(--pri-bg-dis) !important;
								&:before {
									border-color: var(--pri-bg-dis) !important;
								}
							}
							>* {
								pointer-events: none;
							}
						}
					}
				}
				&.wide {
					ul, ol {
						width: 100%;
					}
				}
				&.txt-left {
					text-align: left;
				}
				&.txt-center {
					text-align: center;
				}
				&.txt-right {
					text-align: right;
				}
				ul,
				ol {
					display: inline-flex;
					flex-direction: column;
				}
				li {
					cursor: pointer;
					position: relative;
					display: flex;
					align-items: center;
					margin: 10px 0;
					padding: 10px;
					list-style-type: none;
					border-radius: 8px;
					color: var(--task-col);
					border: 1px solid var(--task-col);
					background: var(--task-bg);
					transition: .3s ease-in-out;
					&:before {
						content: '';
						width: 20px;
						min-width: 20px; // prevent squish
						height: 20px;
						text-indent: 20px;
						white-space: nowrap;
						margin	: 0 10px 0 0;
						border: 1px solid var(--task-col);
						overflow: hidden;
						transition: border .3s ease-in-out;
					}
					svg {
						pointer-events: none;
						position: absolute;
						top: 50%;
						left: 20px;
						transform: translate(-50%,-50%);
						opacity: 0;
						.fill {
							fill: var(--task-col);
						}
					}
					&.selected {
						color: var(--task-col-sel);
						background: var(--task-bg-sel);
						border-color: var(--task-bg-sel);
						&:before {
							border-color: var(--task-col-sel);
						}
						>svg {
							opacity: 1;
							.fill {
								fill: var(--task-col-sel);
							}
						}
						&:hover {
							border-color: var(--task-bg-sel-hvr);
							background: var(--task-bg-sel-hvr);
							&:before {
								border-color: var(--task-col-sel-hvr);
							}
							>svg {
								.fill {
									fill: var(--task-col-sel-hvr);
								}
							}
						}
					}
					&:not(.selected):hover {
						color: var(--task-col-hvr);
						border-color: var(--task-col-hvr);
						background: var(--task-bg-hvr);
						&:before {
							border-color: var(--task-col-hvr);
						}
					}
				}
			}
			&.radio {
				li:before {
					border-radius: 50%;
				}
			}
			&.checkbox {
				li:before {
					border-radius: 2px;
				}
			}
			.feedback {
				z-index: 120; // above tint
				position: absolute !important;
				bottom: 40px;
				left: 50%;
				transform: translateX(-50%);
				width: calc(100% - 60px);
				max-width: 900px;
				padding: 40px;
				border-radius: 8px;
				background: #FFF;
				box-shadow: 0 5px 15px -5px rgba(#000, .25);
				span {
					display: block;
					margin-right: 10px;
					transform: translateY(2px);
					.fill {
						fill: var(--hdg-col);
					}
				}
				h4 {
					display: flex;
					justify-content: center;
					color: var(--hdg-col);
					font-size: 2.4rem;
					font-weight: 400;
				}
				&.active {
					visibility: visible;
					opacity: 1;
				}
			}
			&.freeform {
				>div {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					&.submitted {
						.p,
						small {
							color: var(--pri-col-dis) !important;
						}
						.p {
							cursor: not-allowed;
							border-color: var(--pri-bg-dis) !important;
						}
					}
				}
				p {
					font-size: 1.8rem;
					margin: 0 auto 20px auto;
					text-align: center;
				}
				.p {
					width: 100%;
					margin-bottom: 30px;
					padding: 15px;
					border-radius: 8px;
					color: var(--task-col);
					border: 1px solid var(--task-col);
					background: var(--task-bg);
					transition: border .3s ease-in-out;
					div {
						&:not(:first-child) {
							margin-top: 20px;
						}
					}
					&:hover {
						border-color: var(--task-col-hvr);
						background: var(--task-bg-hvr);
					}
				}
				small {
					align-self: flex-start;
					margin: -20px 0 30px 0;
					padding: 0 10px;
					font-size: 1rem;
					text-transform: uppercase;
					font-weight: 500;
					line-height: 2rem;
					color: var(--task-col);
				}
				strong {
					padding: 0 10px 10px 10px;
					font-size: 1.8rem;
					font-weight: 400;
					text-align: center;
				}
				&.txt-left {
					>div {
						align-items: flex-start;				
					}
				}
				&.txt-center {
					>div {
						align-items: center;
					}
				}
				&.txt-right {
					>div {
						align-items: flex-end;
					}
					small {
						align-self: flex-end;
					}
				}
				&.multiline {
					.p {
						min-height: 200px;
						padding-bottom: 20px;
					}				
				}
			}
		}
		>div {
			width: 100%;
			max-width: 900px;
			margin: auto;
		}
		picture {
/*			&.contain {
				img {
					object-fit: contain;
				}
			}*/
			&.icon {
				display: block;
				width: 50px;
				height: 50px;
				font-size: 50px;
				margin-bottom: 10px;
				>[class^="material-symbols"] {
					font-size: inherit;
					color: var(--hdg-col);
				}
				>.diamond {
					transform: scale(1.5) translateX(2%);
				}
				>.eco {
					transform: scale(1.75) translate(2%, -6%);
				}
				>.key {
					transform: scale(1.6) translate(2%, 2%) rotate(45deg);
				}
				>.workspace_premium {
					transform: scale(1.35);
					//transform: scale(1.35) translate(-2%, -2%) rotate(-45deg);
				}
				.weight {
					transform: scale(1.8) translateY(-2%);
				}
				.forest {
					transform: scale(1.5) translateY(-3%);
				}
				>.explore,
				>.signpost,
				>.sentiment_dissatisfied,
				>.sentiment_satisfied,
				>.sentiment_neutral {
					transform: scale(1.4);
				}
				>.egg {
					transform: scale(1.65);
				}
				>.local_cafe {
					transform: scale(1.6) translateX(-1%);
				}
				>.hive {
					transform: scale(1.3);
				}
				>.search,
				>.coronavirus,
				>.rocket_launch {
					transform: scale(1.5);
				}
				.favorite {
					transform: scale(1.6) translateY(3%);
				}					
			}
		}
	}
}
#front {
	.block {
		picture {
			&.contain {
				//background: #000;
				img {
					object-fit: contain !important;
				}
			}
		}
		figcaption {
			z-index: 1;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			display: flex;
			align-items: flex-end;
			padding: 0 15px 15px 15px;
			font-size: 1.4rem;
			color: #FFF !important; // prevent focus recolouring
			&:before {
				z-index: -1;
				pointer-events: none;
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;
				min-height: 50px;
				background: linear-gradient(transparent, rgba(#000, .7));
			}
		}
		&.image {
			figure,
			picture {
				margin: 0;
			}
			img,
			picture {
				display: block;
			}
			&.single {
				picture {
					display: block;
					width: 100%;
//					margin: 0;
					&:empty { // placeholder
						background: $lightgrey url(/img/icon-photo.svg) center no-repeat;
						background-size: 30px 30px;
					}
					&:before {
						z-index: 1;
						position: relative;
						content: '';
						display: block;
						width: 100%;
						background: rgba(#FFF, .5);
						opacity: 0;
						transition: opacity .3s ease-in-out;
					}
					img {
						width: 100%;
						height: auto;
					}
					&[class*="ratio"] {
						img {
							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
							object-fit: cover; // defaults to center positioning
						}
					}
				}
			}
			&.carousel {
				>div {
					position: relative;
					&:before { // ratio
						z-index: 1;
						position: relative;
						content: '';
						display: block;
						width: 100%;
						background: rgba(#FFF, .5);
						opacity: 0;
						transition: opacity .3s ease-in-out;
					}
				}
				&.fullwidth {
					>div {
						max-width: none;
					}
				}
				figure {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
					transition: opacity .3s .3s ease-in-out;
					&.active {
						z-index: 1;
						pointer-events: auto;
						transition-delay: 0s;
						opacity: 1;
					}
				}
				picture {
					display: block;
					width: 100%;
					height: 100%;
				}
				ol {
					z-index: 2;
					position: absolute;
					bottom: 40px;
					left: 50%;
					transform: translateX(-50%);
					display: flex;
					li {
						margin: 0 5px;
						list-style-type: none;
					}
					button {
						display: block;
						width: 20px;
						height: 20px;
						text-indent: 20px;
						white-space: nowrap;
						overflow: hidden;
						border-radius: 50%;
						border: 2px solid #FFF; // leave
						transition: background .3s ease-in-out;
						background: none;
						&:hover,
						&.active {
							cursor: pointer;
							background: #FFF // leave;
						}
					}
				}
			}
			&.withtext {
				&.cover {
					>div {
						align-items: normal !important;
						figure {
							margin: 0 !important;;
						}
					}
				}
				>div {
					display: flex;
					justify-content: space-between;
					padding: 0;					
					>* {
						width: calc(50% - 20px);
					}
					&.txttop {
						align-items: flex-start;
						figure {
							img {
								object-position: top;
							}
						}
						.txt {
							margin-bottom: auto;
						}
					}
					&.txtmiddle {
						align-items: center;
						figure {
							img {
								object-position: center;
							}
						}
						.txt {
							margin-top: auto;
							margin-bottom: auto;
						}
					}
					&.txtbottom {
						align-items: flex-end;
						figure {
							img {
								object-position: bottom;
							}
						}
						.txt {
							margin-top: auto;
						}
					}
				}
				picture {
					height: 100%;
					&:before {
						content: '';
						display: block;
						width: 100%;
					}
				}
				figure {
					position: relative;
				}
				.txt {
					display: flex;
					flex-direction: column;
					&.txt-left {
						text-align: left;
					}
					&.txt-center {
						text-align: center;
					}
					&.txt-right {
						text-align: right;
					}
					&.whitetext {
						>* {
							color: #FFF !important;
						}
					}
				}
				&.cover {
					padding-top: 0;
					padding-bottom: 0;
					figure {
					}
					.txt {
						padding: 40px 0;
					}
				}
				@media only screen and (max-width: 768px) {
					>div {
						flex-wrap: wrap;
						>* {
							width: 100%;
						}
					}
					figure {
						&.left {
							margin-bottom: 40px;
						}
						&.right {
							order: 1;
							margin-top: 40px;
						}
					}
				}
				@media only screen and (min-width: 769px) {
					figure {
						&.right {
							transform: translateX(100%) translateX(40px);
							+.txt {
								transform: translateX(-100%) translateX(-40px);
							}
						}
					}
				}
			}
		}
		&.stream {
			&.video {				
				&.fullwidth {
					>div {
						max-width: none;
					}
				}
				figure {
					position: relative;
					&:before {
						content: '';
						display: block;
						width: 100%;
					}
				}
				video {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
				figcaption { // overrides
					bottom: auto;
					top: 0;
					padding: 15px 15px 0 15px;
					&:before {
						top: 0;
						bottom: auto;
						background: linear-gradient(rgba(0, 0, 0, 0.7), transparent);
					}
				}
			}
			&.audio {
				>div {
					display: flex;
					justify-content: center;
				}
			}
		}
	}
	.block.image.single {
		figure {
			position: relative;
			display: flex;
			flex-direction: column;
			width: 100%;
			margin: auto;
		}
		&.fullwidth {			
			picture {
				max-height: 65vh;
				overflow: hidden;
			}
		}
		&:not(.fullwidth) {
			figure {
				max-width: 1060px; // 900 + 80 (pad) x 2
			}
		}
		
		/*picture {
			display: block;
			width: 100%;
			margin: 0;
			&:empty { // placeholder
				background: $lightgrey url(/img/icon-photo.svg) center no-repeat;
				background-size: 30px 30px;
			}
			&:before {
				z-index: 1;
				position: relative;
				content: '';
				display: block;
				width: 100%;
				background: rgba(#FFF, .5);
				opacity: 0;
				transition: opacity .3s ease-in-out;
			}
			img {
				width: 100%;
				height: auto;
			}
			&[class*="ratio"] {
				img {
					position: absolute;
					top: 0;
					left: 0;
					height: 100%;
					object-fit: cover; // defaults to center positioning
				}
			}
		}*/
	}
}
</style>
