import AuthService from '../services/AuthService';
import { getField, updateField } from 'vuex-map-fields';

export const auth = {
	namespaced: true,
	state: {
		checked: false,
		loggedIn: false,
		user: null,
	},
	mutations: {
		SIGNUP_SUCCESS(state) {
			state.loggedIn = false;
		},
		SIGNUP_FAILURE(state) {
			state.loggedIn = false;
		},
		SIGNIN_SUCCESS(state, user) {
			state.checked = true;
			state.loggedIn = true;
			state.user = user;
		},
		SIGNIN_FAILURE(state) {
			state.checked = true;
			state.loggedIn = false;
			state.user = null;
		},
		SIGNOUT_SUCCESS(state) {
			state.loggedIn = false;
			state.user = null;
		},
		/*SET_USER_PARAM(state, payload) {
			state.user[payload.param] = payload.value;
		},
		SET_USER_ORGANISATION_PARAM(state, payload) {
			state.user.userOrganisations[0][payload.param] = payload.value;
		},*/
		updateField,
	},
	getters: {
		getField,
		getUserRole(state, getters, rootstate) {
			// check if user belongs directly to organisation
			const user = state.user;
			const orgId = rootstate.gui.organisation.id;
			let org = user.userOrganisations.find(o => o.organisationId == orgId) || null;
			
			if (org) {
				return org.role;
				
			} else {
				// check if user belongs to ancestor organisation
				org = user.userOrganisations.find(uo => uo.decendantOrganisations.some(o => o.id == orgId)) || null;
				
				return org.role;
			}
		},
		getUserSeniority(state, getters) {
			const role = getters.getUserRole;
			return role.seniority;
		},
	},
	actions: {
		async status({ commit }) {
			try {
				const response = await AuthService.status();
				if (response.data.loggedIn) {
					commit('SIGNIN_SUCCESS', response.data.user);
				} else {
					commit('SIGNIN_FAILURE');
				}
				
			} catch (error) {
				commit('SIGNIN_FAILURE');
				// pass error to caller
				throw error;
			}
		},
		async signin({ commit }, formData) {
			try {
				const response = await AuthService.signin(formData);
				commit('SIGNIN_SUCCESS', response.data.user);
				
			} catch (error) {
				commit('SIGNIN_FAILURE');
				// pass error to caller
				throw error;
			}
		},
		async signout({ commit }) {
			try {
				await AuthService.signout();
				commit('SIGNOUT_SUCCESS');
				
			} catch (error) {
				console.log(error.response.data);
				// pass error to caller
				throw error;
			}
		},
		async signup({ commit }, user) {
			try {
				await AuthService.signup(user);
				commit('SIGNUP_SUCCESS');
				
			} catch (error) {
				commit('SIGNUP_FAILURE');
				// pass error to caller
				throw error;
			}
		},
		/*setUserParam({ commit, state }, params) {
			for (let param of Object.keys(params)) {
				if (param in state.user) {
					commit('SET_USER_PARAM', {
						param: param,
						value: params[param],
					});
					
				} else if (param in state.user.userOrganisations[0]) {
					commit('SET_USER_ORGANISATION_PARAM', {
						param: param,
						value: params[param],
					});
				}
			}
		},*/
	}
};
