<template lang="pug">
include ../pug/svg
div#sign
	aside
		a#logo
			img(v-if="gui.orgInitiated" :src="logoWhiteoutUrl" :alt="gui.organisation.name")
		picture.ratio
			img(v-if="heroUrl" :src="heroUrl" alt="")
		p {{gui.authScreen.strapline.pri}}
			small(v-if="gui.authScreen.strapline.sec") {{gui.authScreen.strapline.sec}}
			a.but.pri(v-if="gui.authScreen.cta.enable" :href="gui.authScreen.cta.url" :target="target") {{gui.authScreen.cta.text}}
	router-view(v-slot="{Component}")
		transition(name="sign" mode="out-in" enter-active-class="hide" leave-active-class="hide" appear)
			component(:is="Component")
</template>

<script>
export default {
	name: 'Sign',
	computed: {
		loggedIn() {
			return this.$store.state.auth.loggedIn;
		},
		gui() {
			return this.$store.state.gui;			
		},
		logoWhiteoutUrl() {
			return this.gui.organisation.logoWhiteoutUrl ? this.gui.organisation.logoWhiteoutUrl : '/img/logo-thinkhub-short-wo.svg';			
		},
		heroUrl() {
			return this.gui.organisation.heroUrl ? this.gui.organisation.heroUrl : '/img/hero-thinkworks-mountain.jpg';			
		},
		target() {
			return this.gui.authScreen.cta.window ? '_blank' : '_self';
		},
	},
}
</script>

<style lang="scss">
#sign.box-shadow {
	.form picture,
	.form .alt,
	.field {
		box-shadow: $box_shw;
	}
}
#sign { // transition in/out
	transition: background-color .6s ease-in-out;
	&:before,
	aside,
	main {
		transition: all .6s ease-in-out;
	}
	&.hide {
		background-color: transparent;
		aside,
		main,
		&:before {
			opacity: 0;
		}
		aside {
			transform: translateX(-100%);
		}
		main {
			transform: translateX(100%);
		}
	}
}
#sign {
	display: flex;
	#imgLoader {
		pointer-events: none;
		position: absolute;
		top: 0;
		right: 100%;
		width: 1px;
		height: 1px;
		opacity: 0;
	}
	#logo {
		z-index: 1;
		position: absolute;
		top: 20px;
		left: 30px;
		width: 200px;
		height: 50px;
		img {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: contain;
			object-position: left top;
		}
	}
	aside {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 20px;
		padding-top: 90px;
		width: 40%;
		min-width: 340px;
		max-width: 760px;
		picture {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			&:after { // tint
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(#000, .4); // add tint as var?
			}
		}
		p {
			position: relative;
			margin: 135px 0 0 0;
			font-size: 3rem;
			font-weight: 300;
			color: #FFF; // leve
			small {
				display: block;
				margin: 10px 0;
				font-size: 1.6rem;
				font-weight: 400;
			}
			strong {
				font-weight: 400;
			}
		}
	}
	main {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-grow: 1;
		width: 60%;
		min-height: 100vh;
		overflow: hidden;
		padding: 20px 30px 0 30px;
		&.spacer:before { // spacer when sign up not present
			content: '';
			display: block;
			height: 50px;
		}
		h1,
		.h1 {
			line-height: 1.1;
			font-size: 3rem;
			font-family: var(--h1-fam);
			font-weight: var(--h1-wgt);
			text-transform: var(--h1-uc);
			color: var(--h1-col);
		}
		#signup {
			text-align: right;
			&:first-child {
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
			span {
				margin-right: 10px;
			}
		}
	}
	.form {
		display: flex;
		width: 200%;
		text-align: center;
		transition: transform .6s ease-in-out;
		/*>form,
		>div {
			position: relative;
			width: 50%;
			&:before { // spacer for profile pic
				content: '';
				display: block;
				width: 100%;
				padding-top: 150px;
				margin-bottom: 20px;
			}
		}*/
		form {
			position: relative;
			width: 50%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			&:before { // spacer for profile pic
				content: '';
				display: block;
				width: 100%;
				padding-top: 150px;
				margin-bottom: 20px;
			}

		}
		picture,
		.alt {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			width: 150px;
			height: 150px;
//box-shadow: var(--box_shw, $box_shw);
			border-radius: 50%;
		}
		picture {
			overflow: hidden;
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
		.alt {
			display: flex;
			width: 150px;
			height: 150px;
			justify-content: center;
			align-items: center;
			color: #FFF;
			text-transform: uppercase;
			font-size: 30px;
			font-weight: 300;
			margin-bottom: 20px;
			background: var(--pri-bg);
		}
		&.step2 {
			transform: translateX(-50%);
		}
		.req {
			margin-bottom: 20px;
		}
	}
	p {
		font-size: 1.4rem;
		&.forgot {
			width: 100%;
			max-width: 400px;
			margin: -16px 0 0 0;
			padding-right: 5px;
			line-height: 1;
			text-align: right;
			a {
				cursor: pointer;
				font-size: 1.2rem;
				color: var(--pri-bg);
				text-decoration: underline;
				text-decoration-color: transparent;
				transition: .3s ease-in-out;
				&:hover {
					text-decoration-color: var(--pri-bg);
				}
			}
		}
		&.err {
			color: $invalid;
		}
	}
	@media (max-width: 919px) {
		main #signup,
		#copy {
			justify-content: center;
		}
	}
	@media (max-width: 767px) {
		aside {
			display: none;
		}
	}
	@media (min-width: 541px) {
		br { // h1/h2
			display: none;
		}
	}
}
</style>
