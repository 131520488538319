<template lang="pug">
include ../pug/svg
div#th(:class="{menu:!setMenu,tint:tintState}")
	aside
		div
			div
				a#logo
					img(v-if="originS3(logoColourUrl) || originImgDir(logoColourUrl)" :src="logoColourUrl" :alt="org.name")
				SelectSearch(v-if="canAddOrganisation||usrOrganisationOpts.length>1" id="org" :options="usrOrganisationOpts" v-model:selected="usrOrganisationOpt" @add:option="toggleModal(true)" searchMode="startsWith" :addOption="canAddOrganisation" :setTint="false")
				nav#main
					template(v-if="hasFeature('messages')||hasFeature('calls')")
						h6 {{terminology('interface','comms','singular')}}
						ul
							li(v-if="hasFeature('messages')")
								router-link(:to="{name:'Messages'}") Messages
								span.count(:class="{disabled:!unreadMessageCount}") {{unreadMessageCount}}
							li(v-if="hasFeature('calls')")
								router-link(:to="{name:'Calls'}") Calls
								//-span.count 0	
					h6 {{terminology('interface','learn','singular')}}
					ul
						li
							router-link(:to="{name:'Learn',params:{ state: 'active'}}") Active {{terminology('course','course','plural')}}
							span.count(:class="{disabled:!activeLearningCount}") {{activeLearningCount}}
						li
							router-link(:to="{name:'Learn',params:{ state: 'completed'}}") Completed {{terminology('course','course','plural')}}
							span.count(:class="{disabled:!completedLearningCount}") {{completedLearningCount}}
					template(v-if="usrSeniority<=50")
						h6 {{terminology('interface','manage','singular')}}
						ul
							li(v-if="hasFeature('course')")
								router-link(:to="{name:'ManageLearning'}") {{terminology('course','course','plural')}}
							li(v-if="hasFeature('people')")
								router-link(:to="{name:'ManagePeople'}") {{terminology('user','user','plural')}}
							li(v-if="hasFeature('media')")
								router-link(:to="{name:'ManageMedia'}") {{terminology('media','media','plural')}}
							li(v-if="hasFeature('theme')")
								router-link(v-if="hasFeature('theme','style')" :to="{name:'ManageThemeStyle'}") {{terminology('theme','theme','singular')}}
								router-link(v-else-if="hasFeature('theme','font')" :to="{name:'ManageThemeFont'}") {{terminology('theme','theme','singular')}}
								router-link(v-else-if="hasFeature('theme','colour')" :to="{name:'ManageThemeColour'}") {{terminology('theme','theme','singular')}}
								router-link(v-else-if="hasFeature('theme','terminology')" :to="{name:'ManageThemeTerminology'}") {{terminology('theme','theme','singular')}}
							li(v-if="hasFeature('account')||usrSeniority<=20")
								router-link(v-if="hasFeature('account','profile')" :to="{name:'ManageAccountProfile'}") Account
								router-link(v-else-if="hasFeature('account','organisation')" :to="{name:'ManageAccountOrganisation'}") Account
								router-link(v-else-if="usrSeniority<=20" :to="{name:'ManageAccountFeature'}") Account
					template(v-else)
						h6 Account
						ul
							li
								router-link(:to="{name:'ManageAccountProfile'}") Account
							li
								router-link(:to="{name:'Signin', params:{signout:true}}") {{terminology('interface','signout','singular')}}
	main
		div#menu
			router-link(:to="{name:header.backRoute.name, params:header.backRoute.params}")
				//-router-link(v-else :to="{name:header.backRoute.name}")
				span {{header.backRoute.text}}
				+svg(svg-filename="iconArrow" aria-hidden="true" alt="Back icon")
			button.but(type="button" @click="setMenu=!setMenu") Toggle Menu
				+svg(svg-filename="iconMenu" aria-hidden="true" alt="Menu icon")
		h1 {{header.title}}
		div#top
			ul
				//-li
					router-link#help(:to="{name:'Help'}")
						+svg(svg-filename="iconLifebelt") Help
				li
					button#acc.but.user.online(type="button" aria-haspopup="true" aria-contols="accmenu")
						img(v-if="usr.profileUrl && originS3(usr.profileUrl)" :src="usr.profileUrl" width="40" height="40" :alt="usr.fullName")
						span(v-else) {{usr.initials}}
					nav#accmenu.menu
						div.bar
							+svg(svg-filename="iconMore" aria-hidden="true")
						ul
							li(v-if="hasFeature('account','profile')")
								router-link(:to="{name:'ManageAccountProfile'}") Edit Profile
							li
								router-link(:to="{name:'Signin', params:{signout:true}}") {{terminology('interface','signout','singular')}}
								
		div.modal.narrow.center(:class="{active:showModal}")
			div.bar
				+svg(svg-filename="iconMore" aria-hidden="true")
				span Add {{terminology('organisation','organisation','singular')}}
				button.but(type="button" @click="toggleModal(false)") Close
					+svg(svg-filename="iconClose")
			Form.body(ref="organisationForm" :validation-schema="organisationSchema" v-slot="{ errors, isSubmitting, values, meta }" @submit="addOrganisation")
				
				FieldText(name="name" :label="terminology('organisation','organisation','singular')+' Name'" v-model="name" :required="true" :errors="errors" @keyup="setSubdomain()")
				
				FieldText(name="subdomain" label="Subdomain" v-model="subdomain" :required="true" :errors="errors" :maxlength="maxSubdomainLength" :append="getDomain" @keyup="manualSubdomain=true")
				
				div.foot
					span.req Required
					button.but.pri.spinner(type="submit" :class="{spin:isSubmitting}" :disabled="!meta.valid||isSubmitting||!meta.touched") Add
					button.but.sec(type="button" @click="toggleModal(false)") Cancel
		router-view(v-slot="{Component}")
			transition(name="th" mode="out-in" enter-active-class="hide" leave-active-class="hide" appear)
				component(:is="Component")
		Copyright
	div#zoom
</template>

<script>
import SocketioService from '../services/SocketioService';

import SelectSearch from '../components/SelectSearch';
import FieldText from '../components/FieldText';
import Copyright from '../components/Copyright';
import { Form } from 'vee-validate';
import * as Yup from 'yup';

import OrganisationService from '../services/OrganisationService';

export default {
	name: 'ThinkHub',
	components: {
		Copyright,
		Form,
		SelectSearch,
		FieldText,
	},
	data() {
		const req = 'Required';
//		const inv = 'Invalid';
		const organisationSchema = Yup.object().shape({
			name: Yup.string().required(req).nullable(),
			subdomain: Yup.string().matches(/^[a-z]+$/, 'Lowercase letters only').required(req).nullable(),			
		});		
		
		return {
			organisationSchema,
			name: null,
			subdomain: null,
			setMenu: true,
			showModal: false,
			manualSubdomain: false,
			maxSubdomainLength: 10,
		}
	},
	computed: {
		tintState() {
			return this.$store.state.gui.tintState;
		},
		header() {
			return this.$store.state.gui.header;
		},
		org() {
			return this.$store.state.gui.organisation;
		},
		logoColourUrl() {
			return this.org.logoColourUrl ? this.org.logoColourUrl : '/img/logo-thinkhub-abbr.svg';
		},
		usr() {
			return this.$store.state.auth.user;
		},
		canAddOrganisation() {
			return (this.usr.userOrganisations[0].role.seniority <= 30);
		},
		usrSeniority() {
			return this.$store.getters['auth/getUserSeniority'];
		},
		unreadMessageCount() {
			return this.$store.state.users.messages.filter(m => m.toId === this.usr.id && !m.read).length;
		},
		usrOrganisationOpts() {
			// user thinkhub associations
			let subdomains = [];
			
			for (let userOrganisation of this.usr.userOrganisations) {
				// top level thinkhubs
				subdomains.push({
					option: userOrganisation.organisation.name,
					value: userOrganisation.organisation.subdomain,
				});
				// decendant thinkhubs
				const decendants = userOrganisation.decendantOrganisations.map(o => {
					return {
						option: o.name,
						value: o.subdomain,
					};
				});
					
				subdomains.push(...decendants);
			}
			
			// remove duplicates and sort alphabetically
			return subdomains.filter((obj, index) => {
				return index === subdomains.findIndex(o => obj.value === o.value);
			}).sort((a, b) => {
				if (a.value > b.value) {
					return 1;
					
				} else if (a.value < b.value) {
					return -1;
				}
				
				return 0;
			});
		},
		usrOrganisationOpt: {
			get() {
				return this.usrOrganisationOpts.find(o => o.value === this.org.subdomain);
			},
			set(option) {
				// notify socket that leaving hub
				SocketioService.userSwitchingOrganisation();
				
				// switch organisation
				this.switchOrganisation(option.value);
			}
		},
		usrCourses() {
			const courses = this.$store.getters['courses/getUserCourses'];
			// remove non-active courses
			return courses.filter(c => c.publishStatus === 'enrol' || c.publishStatus === 'live');
		},
		activeLearningCount() {
			const activeCourses = this.usrCourses.filter(c => {
				const completedAttempt = c.courseUserActivities.find(a => a.userId === this.usr.id && a.activityType === 'course' && a.activitySubtype === 'completed')||null;
				
				return (completedAttempt === null);
			});
			
			return activeCourses.length;
		},
		completedLearningCount() {
			const completedCourses = this.usrCourses.filter(c => {
				const completedAttempt = c.courseUserActivities.find(a => a.userId === this.usr.id && a.activityType === 'course' && a.activitySubtype === 'completed')||null;
				return (completedAttempt !== null);
			});
			
			return completedCourses.length;
		},
		getDomain() {
			// remove current subdomain
			const re = new RegExp('^' + this.org.subdomain, 'i');
			return window.location.hostname.replace(re, '');
		},
	},
	methods: {
		hasFeature(feature, subfeature) {
			return this.$store.getters['gui/hasFeature'](feature, subfeature);
		},
		originS3(url) {
			return url.startsWith('https://thinkhub.s3');
		},
		originImgDir(url) {
			return url.startsWith('/img/');
		},
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		toggleModal(bool) {
			this.showModal = bool;
			
			if (this.showModal) {
				this.$refs.organisationForm.resetForm();
				this.$store.dispatch('gui/setTintState', true);
				
			} else {
				this.$store.dispatch('gui/setTintState', false);
			}
		},
		setSubdomain() {
			// match name until manually changed
			this.subdomain = (this.manualSubdomain) ? this.subdomain : this.name.replace(/[^a-z]/gi, '').toLowerCase().substring(0, this.maxSubdomainLength);
		},
		switchOrganisation(subdomain) {
			// switch organisation
			const re = new RegExp('^' + this.org.subdomain, 'i');
			window.location.href = window.location.protocol + '//' + window.location.host.replace(re, subdomain) + '/dashboard';
		},
		async initHubData() {
			// load people from db
			this.$store.dispatch('users/dbUsers');
			// load courses from db
			this.$store.dispatch('courses/dbCourses');
			// load messages from db
			this.$store.dispatch('users/dbMessages');
		},
		async addOrganisation(values, actions) {
			try {
				await OrganisationService.createOrganisation({
					name: values.name,
					subdomain: values.subdomain,
				});
				
				// switch to new organisation
				this.switchOrganisation(values.subdomain);
				
			} catch(err) {
				// flag error in form
				actions.setFieldError('subdomain', err.response.data.message);
			}
		},
	},
	mounted() {
		this.$store.dispatch('gui/setTintState', false);
		this.initHubData();
	},
	created() {
		// add to logged in users and broardcast
		SocketioService.connect();
	},
	beforeUnmount() {
		// broardcast user logged out
		SocketioService.disconnect();
	},
	beforeRouteUpdate(to, from, next) {
		this.$store.dispatch('gui/setTintState', false);
		next();
	}
}
</script>

<style lang="scss">
</style>
