import { createApp } from 'vue';
import App from './App.vue';
//import VeeValidate from 'vee-validate';
import router from './router';
import store from './store';
//import contenteditable from 'vue-contenteditable'; // html content editable
import { plugin as Slicksort } from 'vue-slicksort'; // drag and drop

const app = createApp(App);

app.use(router);
app.use(store);
//app.use(contenteditable);
app.use(Slicksort);

import DropZone from 'dropzone-vue';
//import 'dropzone-vue/dist/dropzone-vue.common.css'; // optional styles
app.use(DropZone);

//import VModal from 'vue-js-modal/dist/index.nocss.js';
//import VModal from 'vue-js-modal';
//app.component('VModal', VModal);
//app.use(VModal);
/*app.use(VModal, {
	componentName: 'Modal',
	dynamicDefault: {
		draggable: true,
		resizable: false,
	},
});
*/

// add component
import McFontpicker from '@mikk3lro/mc-fontpicker';
import '@mikk3lro/mc-fontpicker/dist/mc-fontpicker.css';
//app.component("McFontpicker", McFontpicker);
app.component('McFontpicker', McFontpicker);


/*
import { defineRule } from 'vee-validate';

// global validation rules
defineRule('requiredlh', value => {
	if (!value || !value.length) {
		return 'This field is required FUCKER!';
	}

	return true;
});

defineRule('minMax', (value, [min, max]) => {
	if (!value || !value.length) {
		return true;
	}

	const numericValue = Number(value);

	if (numericValue < min) {
		return `This field must be greater than ${min}`;
	}

	if (numericValue > max) {
	return `This field must be less than ${max}`;
	}

	return true;
});

// global validation rules
defineRule('sameAs', (value, [field], ctx) => {
	if (value === ctx.form[field]) {
		return true;
	}

	return 'Passwords must match';
});*/

app.mount('#app');
