import { createStore } from 'vuex';
import { gui } from './guiModule';
import { auth } from './authModule';
import { courses } from './courseModule';
import { users } from './userModule';
import { media } from './mediaModule';
import { calls } from './callModule';
const store = createStore({
	modules: {
		gui,
		auth,
		courses,
		users,
		media,
		calls,
	},
	strict: (process.env.NODE_ENV !== 'production'),
});

// subscribe to store updates
/*store.subscribe((mutation, state) => {
	console.log('gobal store.subscribe', mutation, state);
	// subscribe has to be global so check for correct store update
	if (mutation.type.startsWith('basket/')) { // e.g. module/commit
		// store basket in local storage
		const basket = JSON.stringify(state.basket);

		localStorage.setItem('basket', basket);
	}
});*/

export default store;
