// media data service
import httpApi from '../httpApi';

class MediaService {
	// api calls
	getMedias() {
		return httpApi.get('/media');
	}

	getMediaGroups() {
		return httpApi.get('/media/groups');
	}

	getMediaAuth(id) {
		return httpApi.get('/media/'+id+'/auth');
	}

	updateMedia(data) {
		return httpApi.post('/media', data);
	}

	deleteMedia(id) {
		return httpApi.delete('/media/'+id);
	}
}

export default new MediaService();
