<template lang="pug">
include ../pug/svg
div.selectsearch(:id="id" :class="{active:active}" tabindex="0" @focus="toggleSelect(true)" @focusout="blurSearch($event)")
	+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
	button.but.circ.plus(v-if="addOption===true" @click="addNewOption" :class="{hide:active&&filteredOptions.length}")
		span Add
		+svg(svg-filename="iconPlus" aria-hidden="true" alt="Add icon")
	button.but.circ.plus.clear(type="button" v-if="addOption===true" @click="search=null" :class="{hide:search===null}")
		span Clear
		+svg(svg-filename="iconPlus" aria-hidden="true" alt="Clear icon")
	div {{value}}
	input(v-model="search")
	nav.menu
		ul
			li(v-for="(option, index) in filteredOptions")
				a(role="button" :class="{selected:selected?.value===option.value}" @click="applySelection(option)") {{option.option}}
</template>

<script>
export default {
	name: 'SelectSearch',
	props: ['options','selected','id','placeholder','searchMode','addOption','setTint'],
	emits: ['update:selected','add:option'],
	data() {
		return {
			active: false,
			search: null,
		};
	},
	methods: {
		addNewOption() {
			this.$emit('add:option', {
				value: this.search,
				option: this.search,
			});
		},
		blurSearch(e) { // note: pointer-events none on input is important
			if (!e.relatedTarget) {
				// blur outside of element
				this.toggleSelect(false);
			}
		},
		applySelection(option) {
			this.$emit('update:selected', option);
			this.toggleSelect(true);
		},
		toggleSelect(bool) {
			this.active = bool;
			
			if (!bool) {
				this.search = null;
			}
			if (this.setTint !== false) {
				this.$store.dispatch('gui/setTintState', bool);
			}
		},
	},
	computed: {
		filteredOptions() {
			if (this.search) {
				if (this.searchMode === 'startsWith') {
					return this.options.filter(o => o.option.toLowerCase().startsWith(this.search.toLowerCase()));
					
				} else if (this.searchMode === 'endsWith') {
					return this.options.filter(o => o.option.toLowerCase().endsWith(this.search.toLowerCase()));
					
				} else {
					return this.options.filter(o => o.option.toLowerCase().includes(this.search.toLowerCase()));
				}
			}
			
			return this.options;
		},
		value() {
			return (this.active) ? '' : (this.selected) ? this.selected.option : this.placeholder;
		},
	},
}
</script>

<style lang="scss">
</style>
