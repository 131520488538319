//import GuiService from '../services/GuiService';
import OrganisationService from '../services/OrganisationService';
import { getField, updateField } from 'vuex-map-fields';
//import AuthService from '../services/AuthService';
//import UserService from '../services/UserService';

export const gui = {
	namespaced: true,
	state: {
		// gui settings
		minAwaitTime: 1800, // (miliseconds) 1800 = two cycles of spinner animation
		orgInitiated: false,
		organisation: null,
		header: {
			title: '',
			backRoute: {
				text: null,
				name: null,
				params: null,
			},
		},
		tintState: false,
		showMediaModal: false,
		showEmbedModal: false,
		// reset placeholders
		featureReset: null,
		themeReset: null,
		// font options
		fontOptions: [
			{
				name: 'Roboto',
				weights: ['100','300','400','500','700','900'],
			},
			{
				name: 'Open Sans',
				weights: '300..800',
			},
			{
				name: 'Source Sans Pro',
				weights: ['200','300','400','600','700','900'],
			},
			{
				name: 'Lato',
				weights: ['100','300','400','700','900'],
			},
			{
				name: 'Merriweather',
				weights: ['300','400','700','900'],
			},
			{
				name: 'Montserrat',
				weights: ['100','200','300','400','500','600','700','800','900'],
			},
			{
				name: 'Lora',
				weights: ['400', '700'],
			},
			{
				name: 'Oswald',
				weights: ['200','300','400','500','600','700'],
			},
			{
				name: 'Playfair Display',
				weights: ['400','700','900'],
			},
			{
				name: 'Source Serif Pro',
				weights: ['400','600','700'],
			},
			{
				name: 'Bitter',
				weights: ['400','700'],
			},
			{
				name: 'Fira Mono',
				weights: ['400','500','700'],
			},
			{
				name: 'Pacifico',
				weights: ['400'],
			},
			{
				name: 'Libre Baskerville',
				weights: ['400', '700'],
			},
		],		
		// thinkhub features
		feature: {
			usernames: true,
			messages: false,
			calls: false,
			media: ['developer','principal','owner','admin'],
			people: ['developer','principal','owner','admin'],
			course: {
				add: ['developer','principal','owner','admin'],
				archive: ['developer','principal','owner','admin'],
				content: ['developer','principal','owner','admin'],
				access: ['developer','principal','owner','admin'],
				settings: ['developer','principal','owner','admin'],
				activity: ['developer','principal','owner','admin','mentor'],
			},
			theme: {
				style: ['developer','principal','owner'],
				font: ['developer','principal','owner'],
				colour: ['developer','principal','owner'],
				terminology: ['developer','principal','owner'],
			},
			account: {
				profile: ['developer','principal','owner','admin','mentor','learner'],// change prevented
				organisation: ['developer','principal','owner'],
				feature: ['developer','principal'], // change prevented
			},
		},
		// theme settings
		authScreen: {
			strapline: {
				pri: 'Great minds think alike. Thinkhub',
				sec: 'Unlimited courses. Unlimited people. Unlimited potential.',
			},
			cta: {
				enable: false,
				text: 'Schedule a Demo',
				url: 'https://thinkworks.org.uk/thinkhub',
				window: false,
			},			
			allowSignup: false,
		},
		icon: {
			fill: 1,
			wght: 100,
			grad: -25,
			opsz: 20,
		},
		cssClass: {
			'pri-solid': true,
			'pri-outline': false,
			'sec-solid': false,
			'sec-outline': true,
			'box-shadow': true,
		},
		cssStyle: {
			radius: {
				box: '8px',
				button: '15px',
				user: '50%',
				li: '4px',
				hr: '50%',
			},
		},
		cssFont: {
			h1: {
				name: 'Roboto',
				weights: ['100','300','400','500','700','900'],
				weight: '300',
				uppercase: false,
			},
			heading: {
				name: 'Roboto',
				weights: ['100','300','400','500','700','900'],
				weight: '400',
				uppercase: false,
			},
			subheading: {
				name: 'Roboto',
				weights: ['100','300','400','500','700','900'],
				weight: '500',
				uppercase: true,
			},
			body: {
				name: 'Roboto',
				weights: ['100','300','400','500','700','900'],
				weight: '400',
			},
			label: {
				name: 'Roboto',
				weights: ['100','300','400','500','700','900'],
				weight: '500',
				uppercase: true,
			},
		},
		cssPallet: {
			hex1: {
				hex: 'FF5000',
				name: 'Orange',
			},
			hex2: {
				hex: '444444',
				name: 'Darkest Grey',
			},
			hex3: {
				hex: 'AAAAAA',
				name: 'Grey',
			},
			hex4: {
				hex: 'D8D8D8',
				name: 'Light Grey',
			},
			hex5: {
				hex: 'F5F5F5',
				name: 'Lightest Grey',
			},
			hex6: {
				hex: 'FFFFFF',
				name: 'White',
			},
			hex7: {
				hex: '888888',
				name: 'Dark Grey',
			},
		},
		cssPalletMap: { // map css var to group/pallet key
			interface: {
				name: 'Interface',
				vars: {
					'--bod-bg': { label: 'Background', hex: 'hex5' },
					'--img-bg': { label: 'Image Background', hex: 'hex4' },
				},
			},
			counter: {
				name: 'Counter',
				vars: {
					'--num-bg': { label: 'Background / Active', hex: 'hex1' },
					'--num-col': { label: 'Text / Active', hex: 'hex6' },
					'--num-bg-dis': { label: 'Background / Inactive', hex: 'hex4' },
					'--num-col-dis': { label: 'Text / Inactive', hex: 'hex6' },
				},
			},
			text: {
				name: 'Text',
				vars: {
					'--bod-col': { label: 'Body Text', hex: 'hex2' },
					'--h1-col': { label: 'Main Header', hex: 'hex2' },
					'--hdg-col': { label: 'Section Heading', hex: 'hex2' },
					'--sub-col': { label: 'Section Subheading', hex: 'hex2' },
					'--lnk-col': { label: 'Link / Default State', hex: 'hex1' },
					'--lnk-col-hvr': { label: 'Link / Hover State', hex: 'hex2' },
				},
			},
			list: {
				name: 'Lists',
				vars: {
					'--li-mkr-bg': { label: 'Marker / Background', hex: 'hex1' },
					'--li-mkr-col': { label: 'Marker / Text', hex: 'hex6' },
				},
			},
			label: {
				name: 'Labels',
				vars: {
					'--lab-col': { label: 'Text', hex: 'hex7' },
				},
			},
			navigation: {
				name: 'Navigation Links',
				vars: {
					'--nav-col': { label: 'Default State', hex: 'hex2' },
					'--nav-col-hvr': { label: 'Hover State', hex: 'hex7' },
					'--nav-col-on': { label: 'Active State', hex: 'hex1' },
					'--nav-col-dis': { label: 'Inactive State', hex: 'hex3' },
				},
			},
			navbar: {
				name: 'Navigation Bar',
				vars: {
					'--nb-bg': { label: 'Background', hex: 'hex6' },
					'--nb-lnk': { label: 'Link / Default', hex: 'hex2' },
					'--nb-lnk-hvr': { label: 'Link / Hover', hex: 'hex1' },
					'--nb-lnk-on': { label: 'Link / Active', hex: 'hex1' },
					'--nb-mkr-on': { label: 'Marker / Active', hex: 'hex1' },
					'--nb-but-bg': { label: 'Button Background / Default', hex: 'hex1' },
					'--nb-but-col': { label: 'Button Text / Default', hex: 'hex6' },
					'--nb-but-bg-hvr': { label: 'Button Background / Hover', hex: 'hex2' },
					'--nb-but-col-hvr': { label: 'Button Text / Hover', hex: 'hex6' },
					'--nb-but-bg-dis': { label: 'Button Background / Inactive', hex: 'hex4' },
					'--nb-but-col-dis': { label: 'Button Text / Inactive', hex: 'hex3' },
				},
			},
			panel: {
				name: 'Panels',
				vars: {
					'--pan-bg': { label: 'Background', hex: 'hex6' },
					'--pan-col': { label: 'Body Text', hex: 'hex2' },
					'--pan-hdg-col': { label: 'Heading Text', hex: 'hex2' },
					'--pan-sub-col': { label: 'Subheading Text', hex: 'hex7' },
					'--pan-bar-bg': { label: 'Bar / Background', hex: 'hex2' },
					'--pan-bar-col': { label: 'Bar / Text', hex: 'hex6' },
					'--pan-lnk': { label: 'Background', hex: 'hex1' },
					'--pan-lnk-dis': { label: 'Body Text', hex: 'hex3' },
					'--pan-hr': { label: 'Seperator Line', hex: 'hex4' },
				},
			},
			priButton: {
				name: 'Primary Buttons',
				vars: {
					'--pri-bg': { label: 'Background / Default', hex: 'hex1' },
					'--pri-col': { label: 'Text / Default', hex: 'hex6' },
					'--pri-bg-hvr': { label: 'Background / Hover', hex: 'hex2' },
					'--pri-col-hvr': { label: 'Text / Hover', hex: 'hex6' },
					'--pri-bg-dis': { label: 'Background / Disabled', hex: 'hex4' },
					'--pri-col-dis': { label: 'Text / Disabled', hex: 'hex3' },
				},
			},
			secButton: {
				name: 'Secondary Buttons',
				vars: {
					'--sec-bg': { label: 'Background / Default', hex: 'hex1' },
					'--sec-col': { label: 'Text / Default', hex: 'hex1' },
					'--sec-bg-hvr': { label: 'Background / Hover', hex: 'hex1' },
					'--sec-col-hvr': { label: 'Text / Hover', hex: 'hex6' },
					'--sec-bg-dis': { label: 'Background / Disabled', hex: 'hex4' },
					'--sec-col-dis': { label: 'Text / Disabled', hex: 'hex3' },
				},
			},
			task: {
				name: 'Task Fields',
				vars: {
					'--task-bg': { label: 'Background / Default', hex: 'hex6' },
					'--task-col': { label: 'Text / Default', hex: 'hex2' },
					'--task-bg-hvr': { label: 'Background / Hover', hex: 'hex6' },
					'--task-col-hvr': { label: 'Text / Hover', hex: 'hex1' },
					'--task-bg-sel': { label: 'Background / Selected', hex: 'hex2' },
					'--task-col-sel': { label: 'Text / Selected', hex: 'hex6' },
					'--task-bg-sel-hvr': { label: 'Background / Selected Hover', hex: 'hex1' },
					'--task-col-sel-hvr': { label: 'Text / Selected Hover', hex: 'hex6' },
				},
			},
		},
		termMap: {
			course: {
				name: 'Courses',
				vars: {
					syllabus: {
						default: { singular: 'Syllabus' },
						custom: { singular: 'Overview' },
						active: true,
					},
					course: {
						default: { singular: 'Course', plural: 'Courses' },
						custom: { singular: null, plural: null },
						active: false,
					},
					section: {
						default: { singular: 'Section', plural: 'Sections' },
						custom: { singular: null, plural: null },
						active: false,
					},
					lesson: {
						default: { singular: 'Lesson', plural: 'Lessons' },
						custom: { singular: null, plural: null },
						active: false,
					},
					block: {
						default: { singular: 'Block', plural: 'Blocks' },
						custom: { singular: null, plural: null },
						active: false,
					},
					point: {
						default: { singular: 'Point', plural: 'Points' },
						custom: { singular: null, plural: null },
						active: false,
					},
				},
			},
			user: {
				name: 'Users',
				vars: {
					user: {
						default: { singular: 'User', plural: 'Users' },
						custom: { singular: null, plural: null },
						active: false,
					},
					group: {
						default: { singular: 'Group', plural: 'Groups' },
						custom: { singular: null, plural: null },
						active: false,
					},
					developer: {
						default: { singular: 'Developer', plural: 'Developers' },
						custom: { singular: null, plural: null },
						active: false,						
					},
					principal: {
						default: { singular: 'Principal', plural: 'Principals' },
						custom: { singular: null, plural: null },
						active: false,
					},
					owner: {
						default: { singular: 'Owner', plural: 'Owners' },
						custom: { singular: null, plural: null },
						active: false,
					},
					admin: {
						default: { singular: 'Admin', plural: 'Admins' },
						custom: { singular: null, plural: null },
						active: false,
					},
					mentor: {
						default: { singular: 'Mentor', plural: 'Mentors' },
						custom: { singular: null, plural: null },
						active: false,
					},
					learner: {
						default: { singular: 'Learner', plural: 'Learners' },
						custom: { singular: null, plural: null },
						active: false,
					},
				},
			},
			media: {
				name: 'Media',
				vars: {
					media: {
						default: { singular: 'Media', plural: 'Media' },
						custom: { singular: null, plural: null },
						active: false,
					},
					group: {
						default: { singular: 'Collection', plural: 'Collections' },
						custom: { singular: null, plural: null },
						active: false,
					},
				},
			},
			theme: {
				name: 'Theme',
				vars: {
					theme: {
						default: { singular: 'Theme' },
						custom: { singular: null },
						active: false,
					},
				},
			},
			organisation: {
				name: 'Organisation',
				vars: {
					organisation: {
						default: { singular: 'Organisation', plural: 'Organisations' },
						custom: { singular: null, plural: null },
						active: false,
					},
				},
			},
			interface: {
				name: 'Interface',
				vars: {
					signup: {
						default: { singular: 'Sign Up' },
						custom: { singular: null },
						active: false,
					},
					signin: {
						default: { singular: 'Sign In', plural: 'Signed In' },
						custom: { singular: null, plural: null },
						active: false,
						// new
						placeholderSingular: 'Present tense',
						placeholderPlural: 'Past tense',
					},
					signout: {
						default: { singular: 'Sign Out', plural: 'Signed Out' },
						custom: { singular: null, plural: null },
						active: false,
						// new
						placeholderSingular: 'Present tense',
						placeholderPlural: 'Past tense',
					},
					dashboard: {
						default: { singular: 'Dashboard' },
						custom: { singular: null },
						active: false,
					},
					learn: {
						default: { singular: 'Learn' },
						custom: { singular: null },
						active: false,
					},
					manage: {
						default: { singular: 'Manage' },
						custom: { singular: null },
						active: false,
					},
					comms: {
						default: { singular: 'Comms' },
						custom: { singular: null },
						active: false,
					},
				},
			},
		},
	},
	mutations: {
		updateField,
		INIT_ORGANISATION(state, organisation) {
			state.orgInitiated = true;
			state.organisation = organisation;
		},
		INIT_FEATURE(state, feature) {
			if (state.featureReset === null) {
				// store default values (incase of cancel with no previously saved data)
				state.featureReset = JSON.parse(JSON.stringify(state.feature)); // deep clone hack
				
				if (feature === null) {
					return;
				}
			}
			
			if (feature === null) {
				// no previously saved data so reset to defaults
				feature = JSON.parse(JSON.stringify(state.featureReset)); // deep clone hack				
			}
			
			// update state vars with matched saved data (excludes unmached)
			for (let key in state.feature) {
				if (key in feature) {				
					if (typeof state.feature[key] === 'boolean') {
						// no subfeatures - boolean
						if (typeof feature[key] === 'boolean') {
							state.feature[key] = feature[key];
						}
						
					} else if (Array.isArray(state.feature[key])) {
						// no subfeatures - array or roles
						if (Array.isArray(feature[key])) {
							state.feature[key] = feature[key];
						}
						
					} else {
						// subfeatures present
						for (let subKey in state.feature[key]) {
							if (feature[key][subKey]) {
								if ((typeof state.feature[key][subKey] === 'boolean' && typeof feature[key][subKey] === 'boolean') 
									|| (Array.isArray(state.feature[key][subKey]) && Array.isArray(feature[key][subKey]))) {
									state.feature[key][subKey] = feature[key][subKey];								
								}							
							}
						}
					}
				}
			}
		},
		INIT_THEME(state, theme) {
			if (state.themeReset === null) {
console.log('STORE:themeReset');
				// store default values (incase of cancel with no previously saved data)
				state.themeReset = { // deep clone hack
					termMap: JSON.parse(JSON.stringify(state.termMap)),
					cssPalletMap: JSON.parse(JSON.stringify(state.cssPalletMap)),
					cssFont: JSON.parse(JSON.stringify(state.cssFont)),
					cssStyle: JSON.parse(JSON.stringify(state.cssStyle)),
					authScreen: JSON.parse(JSON.stringify(state.authScreen)),
					cssClass: JSON.parse(JSON.stringify(state.cssClass)),
					icon: JSON.parse(JSON.stringify(state.icon)),
					cssPallet: JSON.parse(JSON.stringify(state.cssPallet)),
				};
				
				if (theme === null) {
					return;
				}
			}
			
			if (theme === null) {
				// no previously saved data so reset to defaults
console.log('RESTORE:themeReset', state.themeReset);
				theme = { // deep clone hack
					termMap: JSON.parse(JSON.stringify(state.themeReset.termMap)),
					cssPalletMap: JSON.parse(JSON.stringify(state.themeReset.cssPalletMap)),
					cssFont: JSON.parse(JSON.stringify(state.themeReset.cssFont)),
					cssStyle: JSON.parse(JSON.stringify(state.themeReset.cssStyle)),
					authScreen: JSON.parse(JSON.stringify(state.themeReset.authScreen)),
					cssClass: JSON.parse(JSON.stringify(state.themeReset.cssClass)),
					icon: JSON.parse(JSON.stringify(state.themeReset.icon)),
					cssPallet: JSON.parse(JSON.stringify(state.themeReset.cssPallet)),
				};
			}
			
console.log('UPDATE:theme', theme);			
			// update state vars with matched saved data (excludes unmached)
			for (let key in state) {
				if (key === 'termMap' || key === 'cssPalletMap') {
					for (let groupKey in state[key]) {
						if (groupKey in theme[key]) {
							for (let varKey in state[key][groupKey].vars) {
								if (varKey in theme[key][groupKey].vars) {
									if (key === 'termMap') {
										state[key][groupKey].vars[varKey].active = theme[key][groupKey].vars[varKey].active;
										state[key][groupKey].vars[varKey].custom = theme[key][groupKey].vars[varKey].custom;
									} else { // cssPalletMap
										state[key][groupKey].vars[varKey].hex = theme[key][groupKey].vars[varKey].hex;
									}
								}
							}
						}
					}
					
				} else if (key === 'cssFont' || key === 'cssStyle' || key === 'authScreen') {
					for (let groupKey in state[key]) {
						if (groupKey in theme[key]) {
							if (typeof state[key][groupKey] === 'boolean') {
								// value
								state[key][groupKey] = theme[key][groupKey];
								
							} else {
								for (let varKey in state[key][groupKey]) {
									if (varKey in theme[key][groupKey]) {
										state[key][groupKey][varKey] = theme[key][groupKey][varKey];
									}
								}								
							}
						}
					}
					
				} else if (key === 'cssClass' || key === 'icon') {
					for (let varKey in state[key]) {
						if (varKey in theme[key]) {
							state[key][varKey] = theme[key][varKey];
						}
					}
					
				} else if (key === 'cssPallet') {
					// replace with plallet (will include custom colour keys)
					for (let groupKey in theme.cssPallet) {
						state.cssPallet[groupKey] = theme.cssPallet[groupKey];
					}
					
				} else {
					// ignore any other saved keys
				}
			}
		},
		SHOW_MEDIA_MODAL(state, payload) {
			state.showMediaModal = payload;
		},
		SET_TINT_STATE(state, payload) {
			state.tintState = payload;
		},
		SET_HEADER_BACKROUTE(state, payload) {
			state.header.backRoute = payload.backRoute;			
		},
		SET_HEADER_TITLE(state, payload) {
			state.header.title = payload;
		},
		SET_CSS_FONT(state, payload) {
			state.cssFont[payload.fontKey] = {
				name: payload.font.name,
				weights: payload.font.weights,
				weight: payload.weight,
				uppercase: payload.uppercase||false,
			};
		},
		SET_CSS_CLASS(state, payload) {
			state.cssClass[payload.class] = payload.bool;
		},
		SET_CSS_PALLET_MAP(state, payload) {
			state.cssPalletMap[payload.groupKey].vars[payload.cssVarKey].hex = payload.hex;
		},
		SET_CSS_PALLET_HEX(state, payload) {
			state.cssPallet[payload.hexKey].hex = payload.hex;
		},
		SET_CSS_PALLET_NAME(state, payload) {
			state.cssPallet[payload.hexKey].name = payload.name;
		},
		ADD_COLOUR(state, payload) {
			state.cssPallet[payload.index] = {
				hex: payload.hex,
				name: payload.name,
			};
		},
		BIN_COLOUR(state, payload) {
			delete state.cssPallet[payload.hexKey];
		},
		SET_TERM_MAP(state, payload) {
			if (payload.varKey === 'active') {
				state.termMap[payload.groupKey].vars[payload.termKey].active = payload.value;
			} else {
				state.termMap[payload.groupKey].vars[payload.termKey].custom[payload.varKey] = payload.value;
			}
		},
		SET_ORGANISATION_PARAM(state, payload) {
			state.organisation[payload.param] = payload.value;
		},
		SET_ICON_PARAM(state, payload) {
			state.icon[payload.param] = payload.value;
		},
	},
	actions: {
		async initOrganisation({ commit }) {
			try {
				const response = await OrganisationService.getBySubdomain();
				
				response.data.organisation.policy = response.data.policy;
				
				commit('INIT_ORGANISATION', response.data.organisation);
				
				if ('theme' in response.data) {
					// previously saved theme or null
					commit('INIT_THEME', response.data.theme);
				}
				
				if ('feature' in response.data) {
					// previously saved features or null
					commit('INIT_FEATURE', response.data.feature);
				}
				
			} catch (error) {
				console.log('error:', error);
				console.log('data:', error.response.data);
			}
		},
		showMediaModal({ commit }, bool) {
			commit('SHOW_MEDIA_MODAL', bool);
		},
		setTintState({ commit }, bool) {
			commit('SET_TINT_STATE', bool);
		},
		setHeader({ commit }, data) {
			if ('title' in data) {
				commit('SET_HEADER_TITLE', data.title);
			}
			
			if ('backRoute' in data) {
				if (!('params' in data.backRoute)) {
					data.backRoute.params = null;
				}
				commit('SET_HEADER_BACKROUTE', data);
			}			
		},
		setHeaderTitle({ commit }, title) {
			commit('SET_HEADER_TITLE', title);
		},
		setOrganisationParam({ commit, state }, params) {
			for (let param of Object.keys(params)) {
				if (param in state.organisation) {
					commit('SET_ORGANISATION_PARAM', {
						param: param,
						value: params[param],
					});
				}
			}
		},
		setCssFont({ commit, state }, data) {
			if (data.fontKey in state.cssFont) {
				commit('SET_CSS_FONT', data);
				//commit('LOG_UPDATE', 'font');
			}
		},
		setCssClass({ commit, state }, data) {
			if (data.class in state.cssClass) {
				commit('SET_CSS_CLASS', data);
			}
		},
		binColour({ commit, state }, data) {
			console.log('binColour:', data);
			if (data.hexKey in state.cssPallet) {
				commit('BIN_COLOUR', data);
			}
		},
		addColour({ commit, state }, data) {
			// update css vars
			const keys = Object.keys(state.cssPallet).map(k => {
				return parseInt(k.replace(/\D/g,''));
			});

			keys.sort().reverse();
			data.index = 'hex' + (keys[0] + 1);

			commit('ADD_COLOUR', data);
			//commit('LOG_UPDATE', 'colour');
		},
		setCssPalletMap({ commit, state }, data) {
			if (data.groupKey in state.cssPalletMap && data.cssVarKey in state.cssPalletMap[data.groupKey].vars) {
				commit('SET_CSS_PALLET_MAP', data);
				//commit('LOG_UPDATE', 'colour');
			}
		},
		setCssPalletHex({ commit, state }, data) {
			if (data.hexKey in state.cssPallet) {
				commit('SET_CSS_PALLET_HEX', data);
				//commit('LOG_UPDATE', 'colour');
			}
		},
		setCssPalletName({ commit, state }, data) {
			if (data.hexKey in state.cssPallet) {
				commit('SET_CSS_PALLET_NAME', data);
				//commit('LOG_UPDATE', 'colour');
			}
		},
		setTermMap({ commit, state }, data) {
			if (data.groupKey in state.termMap && data.termKey in state.termMap[data.groupKey].vars &&
				((data.varKey !== 'active' && data.varKey in state.termMap[data.groupKey].vars[data.termKey].custom) || data.varKey === 'active')
			) {
				commit('SET_TERM_MAP', data);

				const custom = state.termMap[data.groupKey].vars[data.termKey].custom;

				if (data.varKey === 'active' && !custom.singular && !custom.plural) {
					// no custom terms yet
					return;
				}

				//commit('LOG_UPDATE', 'terminology');
			}
		},
		setIcon({ commit, state }, data) {
			if (data.key in state.icon) {
				commit('SET_ICON_PARAM', data);
				//commit('LOG_UPDATE', 'style');
			}
		},
	},
	getters: {
		getField,
		delay(state) {
			return new Promise(
				function(resolve) {
					setTimeout(function() {
						resolve();
					}, state.minAwaitTime); // two spins
				}
			);
		},
		hasFeature: (state, getters, rootstate, rootGetters) => (feature, subfeature) => {
			const authUserRole = rootGetters['auth/getUserRole'];
			
			if (feature && feature in state.feature && authUserRole) {
				if (subfeature) {
					// check against subfeature
					if (subfeature in state.feature[feature]) {
						if (typeof state.feature[feature][subfeature] === 'boolean') {
							// subfeatures restricted by true/false
							return state.feature[feature][subfeature];
							
						} else {
							// subfeatures restricted by roles
							return Object.values(state.feature[feature][subfeature]).includes(authUserRole.slug);
						}
						
					} else {
						// subfeature not found
						return null;
					}
				}
				
				if (typeof state.feature[feature] === 'boolean') {
					// no subfeatures - feature restricted by true/false
					return state.feature[feature];
					
				} else if (Array.isArray(state.feature[feature])) {
					// no subfeatures - feature restricted by roles
					return Object.values(state.feature[feature]).includes(authUserRole.slug);
					
				} else if (Object.values(state.feature[feature]).includes(true)) {
					// feature has subfeatures containing at least one true value
					return true;
					
				} else {
					// feature has subfeatures that are all restricted by roles
					return Object.values(state.feature[feature]).filter(s => s.includes(authUserRole.slug)).length ? true : false;
				}
			}
			
			// feature not found
			return null;
		},
		maxFileSizes(state) {
			return {
				image: {
					max: state.organisation.maxBytesImg,
					exts: ['jpg','png'],
				},
				audio: {
					max: state.organisation.maxBytesMp3,
					exts: ['mp3'],
				},
				video: {
					max: state.organisation.maxBytesMp4,
					exts: ['mp4','mov'],
				},
			};
		},		
		customTerm: (state) => (groupKey, termKey, varKey) => {
			if (groupKey in state.termMap === false || termKey in state.termMap[groupKey].vars === false) {
				// not found so return passsed value
				return (varKey === 'plural') ? termKey+'s' : termKey;
			}
			
			const term = state.termMap[groupKey].vars[termKey] || null;
			return (term.active && term.custom[varKey]) ? term.custom[varKey] : term.default[varKey];
		},
		cssClasses(state) {
			let cssClases = [];

			for (let c in state.cssClass) {
				if (state.cssClass[c]) {
					cssClases.push(c);
				}
			}

			return cssClases;
		},
		cssVars(state) {
			let cssVars = {};

			// colours
			for (const v of Object.keys(state.cssPalletMap).map(k => state.cssPalletMap[k].vars)) {
				for (const [varKey, valKey] of Object.entries(v)) {
					cssVars[varKey] = state.cssPallet[valKey.hex]?.hex ? '#' + state.cssPallet[valKey.hex].hex : null;
				}
			}
			
			// fonts
			cssVars['--h1-fam'] = state.cssFont.h1.name;
			cssVars['--h1-wgt'] = state.cssFont.h1.weight;
			cssVars['--h1-uc'] = (state.cssFont.h1.uppercase) ? 'uppercase' : 'none';
			
			cssVars['--hdg-fam'] = state.cssFont.heading.name;
			cssVars['--hdg-wgt'] = state.cssFont.heading.weight;
			cssVars['--hdg-uc'] = (state.cssFont.heading.uppercase) ? 'uppercase' : 'none';
			//cssVars['--hdg-txt-sze'] = '3rem';

			cssVars['--sub-fam'] = state.cssFont.subheading.name;
			cssVars['--sub-wgt'] = state.cssFont.subheading.weight;
			cssVars['--sub-uc'] = (state.cssFont.subheading.uppercase) ? 'uppercase' : 'none';
			//cssVars['--sub-txt-sze'] = '1.6rem';

			cssVars['--bod-fam'] = state.cssFont.body.name;
			cssVars['--bod-wgt'] = state.cssFont.body.weight;
			//cssVars['--bod-txt-sze'] = '1.4rem';

			cssVars['--lab-fam'] = state.cssFont.label.name;
			cssVars['--lab-wgt'] = state.cssFont.label.weight;
			cssVars['--lab-uc'] = (state.cssFont.label.uppercase) ? 'uppercase' : 'none';
			// styles
			cssVars['--box-rad'] = state.cssStyle.radius.box;
			cssVars['--but-rad'] = state.cssStyle.radius.button;
			cssVars['--user-rad'] = state.cssStyle.radius.user;
			cssVars['--li-rad'] = state.cssStyle.radius.li;
			cssVars['--hr-rad'] = state.cssStyle.radius.hr;
			
			return cssVars;
		},
		googleFontUrl(state) {
			// build google fonts api url
			let fonts = [];
			let families = {
				h1: null,
				heading: null,
				subheading: null,
				body: null,
				label: null,
			};
			
			for (let key in families) {
				const cssFont = state.cssFont[key];
				const font = fonts.find(f => f.family === cssFont.name) || null;
				
				if (font === null) {
					// add new font
					fonts.push({
						family: cssFont.name, 
						weights: [cssFont.weight],
					});
					
				} else if (!font.weights.includes(cssFont.weight)) {
					// add new weight to existing font
					const weights = state.fontOptions.find(f => f.name === font.family).weights;
					
					if (Array.isArray(weights)) {
						// non-variable width font
						font.weights.push(cssFont.weight);
						font.weights.sort((a,b)=>a-b);
					} else {
						// variable width font
						font.weights = [weights];
					}
				}
			}
			
			const googleUrl = 'https://fonts.googleapis.com/css2?' + fonts.map(f => {
				return 'family=' + f.family.replaceAll(/\s/g, '+') + ':wght@' + f.weights.join(';');
			}).join('&') + '&display=swap';
			
			return googleUrl;
		},
	},
};
