<template lang="pug">
include ../pug/svg
main#signin(:class="{spacer:!allowSignup}")
	p#signup(v-if="allowSignup")
		span Don't have an account?
		router-link.but.sec(:to="{name:'Signup'}") Get Started
	div.form(:class="{step2:identified,forgotten:forgotten}")
		img(id="imgLoader" ref="imgLoader")
		Form(:validation-schema="schemaIdentify" v-slot="{ errors, isSubmitting, values, meta }" @submit="doIdentify")
			h1 {{terminology('interface','signin','singular')}} to {{gui.organisation.name}}
			p
				span.err(v-if="errors.email") {{ errors.email }}
				span(v-else) Let's identify you&hellip;
			div.field.req
				Field#email(name="email" type="email" placeholder="Unsupplied" autocomplete="on")
				label(for='email') Email or username
			span.req(aria-hidden="true") Required
			button.but.pri.spinner(:class="{'spin':isSubmitting}" :disabled="!values.email||!meta.valid||isSubmitting" type="submit") Next
				+svg(svg-filename="spinner" aria-hidden="true" alt="Processing")
				
		Form(ref="formSignin" v-if="identified" :validation-schema="schemaSignin" v-slot="{ errors, isSubmitting, values, meta }" @submit="doSignin")
			picture(v-if="user.profileUrl" aria-hidden="true")
				img(:src="user.profileUrl" :alt="user.fullName" width="150" height="150")
			p.alt(v-else aria-hidden="true") {{user.initials}}

			p.h1 Welcome {{user.firstName}}
			p(v-if="forgotten")
				span.err(v-if="errors.passcode") {{ errors.passcode }}
				span(v-else) We've emailed a passcode to your inbox&hellip;			
			p(v-else)
				span.err(v-if="errors.password") {{ errors.password }}
				span.err(v-else-if="signinFailed") Oops, wrong password, please try again!
				span(v-else) Let's get you signed in&hellip;
			div.wrap
				//-pre {{user}}
				div.fieldset
					div.field.req
						Field(name="password" :type="passwordType" placeholder="Unsupplied" autocomplete="off")
						button.but.eye(type="button" @click="togglePasswordType") {{ passwordState }} Password
							+svg(svg-filename="iconHide" aria-hidden="true" alt="Hide icon")
						label(for='password') Password
				div.fieldset
					div.field.req
						Field(name="passcode" type="text" placeholder="Unsupplied" autocomplete="off")
						label(for='passcode') Passcode
			p.forgot(:class="{show:errors.password||errors.passcode}")
				a(:class="{hide:!forgotten}" role="button" @click="undoForgot()") Cancel
				a(:class="{hide:forgotten}" @click="doForgot()") Forgotten your password?

			span.req(aria-hidden="true") Required
			button.but.pri.spinner(:class="{'spin':isSubmitting}" :disabled="!values.password||!meta.valid||isSubmitting" type="submit") Sign In
				+svg(svg-filename="spinner" aria-hidden="true" alt="Processing")
		//-Form(ref="formSecurity" v-if="authenticated" :validation-schema="schemaSecurity" v-slot="{ errors, isSubmitting, values, meta }" @submit="doSecurity")
			p Let's set up your security questions&hellip;
			div.wrap
				div.fieldset
					div.field.req
						Field(name="answer1" type="text" placeholder="Unsupplied" autocomplete="off")
						label(for='answer1') Answer
					div.field.req
						Field(name="answer2" type="text" placeholder="Unsupplied" autocomplete="off")
						label(for='answer2') Answer
						
			span.req(aria-hidden="true") Required
			button.but.pri.spinner(:class="{'spin':isSubmitting}" :disabled="!values.password||!meta.valid||isSubmitting" type="submit") Save
				+svg(svg-filename="spinner" aria-hidden="true" alt="Processing")
	Copyright
</template>

<script>
import AuthService from '../services/AuthService';
import Copyright from '../components/Copyright';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';

export default {
	name: 'Signin',
	components: {
		Copyright,
		Form,
		Field,
		ErrorMessage,
	},
	data() {
		return {
			user: null,
			identified: false,
			authenticated: false,
			forgotten: false,
			passwordType: 'password',
			passwordState: 'Show',
//			signinFailed: false,
//			forgotFailed: false,
		};
	},
	setup() {
		const schemaIdentify = Yup.object().shape({
			//email: Yup.string().email("Oops, that's an invalid email or username!"),
			email: Yup.string().when('csv', {
				is: (csv) => !csv,
				then: Yup.string().test('emailOrUsername', 'Oops, that\'s an invalid email or username!', (value) => {
					if (value) {
						return value.includes('@') ? Yup.string().email().isValidSync(value) : Yup.string().matches(/^[\w-]+$/).isValidSync(value); 
					}
					
					return false;
					
				}).required('Required'),
			}),
		});
		
		const schemaSignin = Yup.object().shape({
			password: Yup.string().required("Oops, you'll need to enter a password!"),
			//passcode: Yup.string().required("Oops, you'll need to enter a password!"),
		});
		
//		const schemaSecurity = Yup.object().shape({
//		});
		
		return {
			schemaIdentify,
			schemaSignin,
//			schemaSecurity,
		}
	},
	mounted() {
		// check for signout request
		if (this.$route.params.signout) {
			this.$store.dispatch('auth/signout');
		} 
	},
	computed: {
		gui() {
			return this.$store.state.gui;
		},
		allowSignup() {
			return this.gui.authScreen.allowSignup;
		},
/*		authenticated() {
			return this.user.email.includes('@');
		},*/
	},
	methods: {
		async loadImage(url, elem) {
			return new Promise((resolve, reject) => {
				elem.onload = () => resolve(elem);
				elem.onerror = reject;
				elem.src = url;
			});
		},
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		togglePasswordType() {
			if (this.passwordType === 'password') {
				this.passwordType = 'text';
				this.passwordState = 'Hide';
			} else {
				this.passwordType = 'password';
				this.passwordState = 'Show';
			}
		},
		async doIdentify(formData, actions) {
			try {
				const delay = this.$store.getters['gui/delay'];
				const res = await AuthService.identify(formData);
				this.user = res.data.user;
				
				if (this.user.profileUrl) {
					await this.loadImage(this.user.profileUrl, this.$refs.imgLoader);
				}
				
				await delay;
				
				// step two
				this.identified = true;
				
			} catch(err) {
				if (err.response.status === 404) {
					actions.setFieldError('email', "Oops, we couldn't find your email on this thinkhub!");
				}
			}
		},
		async doSignin(formData, actions) {
			try {
				const delay = this.$store.getters['gui/delay'];
				
				await this.$store.dispatch('auth/signin', {
					email: this.user.email,
					password: formData.password,
					passcode: formData.passcode,
				});
				
				await delay;
				
				// sign in success
				if (this.user.email.includes('@')) {
					if (this.$store.state.auth.user.userOrganisations[0].role.slug === 'learner') {
						this.$router.push({name:'Learn', params:{state:'active'}});
					} else {
						this.$router.push({ name: 'ManageLearning' });
					}
					
				} else { // username 
					// step three
					this.authenticated = true;
				}
				
				
			} catch(err) {
				// known or unexpected error
				const field = (this.forgotten) ? 'passcode' : 'password';				
				const msg = (err.response.status === 401) ? 'Oops, incorrect '+field+', please try again!' : 'Error: '+err.response.status;
				
				actions.setFieldError(field, msg);
			}
		},
		async doForgot() {
			try {
				await AuthService.recover({
					email: this.user.email,
				});
				
				this.forgotten = true;
				
			} catch(err) {
				// unexpected error
				this.$refs.formSignin.setFieldError('password', 'Error: '+err.response.status);
			}
		},
		undoForgot() {
			this.forgotten = false;
		},
	},
}
</script>

<style lang="scss">
#signin {
	.form {
		&.step2 {
			&.forgotten {
				.wrap {
					transform: translatex(-50%);
				}
			}
			p.forgot {
				position: relative;
				display: flex;
				justify-content: space-between;
				transition: opacity .3s ease-in-out;
				&:not(.show) {
					opacity: 0;
				}
				a.hide {
					pointer-events: none;
					opacity: 0;
				}
			}
		}
	}
	form {
		overflow: hidden;
	}
	.wrap {
		width: 200%;
		display: flex;
		align-self: flex-start;
		transition: transform .3s ease-in-out;
		.fieldset {
			display: flex;
			width: 50%;
			justify-content: center;
			.field {
			}
		}
	}	
}

</style>
