<template lang="pug">
include ../pug/svg
main#view
	Form(:validation-schema="schema" v-slot="{ errors, isSubmitting }" @submit="doSignup")
		div
			router-link.but.sec(:to="{name:'Signin'}") Sign In
			h1 Sign up
			span() {{ errors }}
			div.field.req
				Field#email(name="email" type="email" placeholder="Unsupplied" autocomplete="on")
				label(for='email') Email Address
				ErrorMessage(name="email")
			div.field.req
				Field#password(name="password" type="password" placeholder="Unsupplied" autocomplete="on")
				label(for='password') Password
				button.but.eye(type="button") Show Password
				ErrorMessage(name="password")
			div.field.req
				Field#confirm(name="confirm" type="password" placeholder="Unsupplied" autocomplete="on")
				label(for='confirm') Confirm Password
				button.but.eye(type="button") Show Password
				ErrorMessage(name="confirm")
			span.req(aria-hidden="true") Required
			button.but.pri.spinner(:class="{'spin':isSubmitting}" :disabled="errors.length||isSubmitting" type="submit") Next
			button.but.sec(type="reset") Revert

	Form(:validation-schema="nameSchema" v-slot="{ errors }" :initial-values="nameValues")
		div
			h1 Sign up
			span() {{ errors }}
			div.field.req
				Field#firstName(name="firstName" type="text" placeholder="Unsupplied" autocomplete="on")
				label(for='firstName') First Name
				ErrorMessage(name="firstName")
			div.field.req
				Field#lastName(name="lastName" type="text" placeholder="Unsupplied" autocomplete="on")
				label(for='lastName') Last Name
				ErrorMessage(name="lastName")
			span.req(aria-hidden="true") Required
			button.but.pri.spinner(type="button" @click="validate" :disabled="false") Next
	Copyright
</template>

<script>
import Copyright from '../components/Copyright';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
//import YupPassword from 'yup-password';

//YupPassword(Yup); // extend yup

export default {
	name: 'Signup',
	components: {
		Copyright,
		Form,
		Field,
		ErrorMessage,
	},

	methods: {
//		doSignin(values, { resetForm }) {
//		},

//		doSignup(values, { resetForm }) {
		doSignup(values, actions) {
			return new Promise(resolve => {
console.log('this.email: ', this.email);
console.log('values: ',values);
				setTimeout(() => {

					actions.setErrors({
						email: 'The email is already in use',
					});

					resolve(JSON.stringify(values, null ,2));
//					console.log('values: ',values);
				}, 5000);

/*
				// tests....
//				resetForm(); // reset to original values
				resetForm({ // reset to new values
					values: {
						email: 'hello@test.com',
					}
				});
*/
			});
		}
	},
//	data() {
//	},
	setup() {
		const req = 'Required';
		const schema = yup.object().shape({
			email: yup.string().email().required(req),
			password: yup.string().required(req),
			confirm: yup.string().test('passwords-match', 'Must match Password', function(value) {
				return this.parent.password === value;
			})
		});
		const nameSchema = yup.object().shape({
			firstName: yup.string().required(req),
			lastName: yup.string().required(req),
		});
		/*
		const schemaSignup = yup.object().shape({
			email: yup.string().email().required(req),
			password: yup.string()
				.required('Required')
				.password()
				.min(8)
				.minLowercase(1)
				.minUppercase(1)
				.minNumbers(1)
				.minSymbols(1),
			confirm: yup.string().test('passwords-match', 'Must match Password', function(value) {
				return this.parent.password === value;
			}),
		});
*/

		const signinValues = {
			password: '12345678',
			confirm: '12345678',
		};

		const nameValues = {
			firstName: 'Laurence',
			lastName: 'Hall',
		};

//		const initialErrors = {
//			email: 'Fuck this',
//		};

		return {
			schema,
			nameSchema,
			nameValues,
			signinValues,
//			initialErrors,
		}
	},
}
</script>

<style lang="scss">
</style>
