import CourseService from '../services/CourseService';
import { getField, updateField } from 'vuex-map-fields';
import router from '../router';

export const courses = {
	namespaced: true,
	state: {
		tempId: 0,
		courses: [],
		courseStatuses: [
			{value: 'draft', option: 'Draft'},
			{value: 'enrol', option: 'Enrolling'},
			{value: 'live', option: 'Live'},
			{value: 'archive', option: 'Archived'},
		],
	},
	getters: {
		getUserCourseRole: (state, getters, rootState) => (courseId) => {
			const course = getters.getCourse(courseId);
			
			if (!course) {
				return null;
			}
			
			const user = rootState.auth.user;
			const orgId = rootState.gui.organisation.id;
			
			// check if user belongs directly to organisation
			let org = user.userOrganisations.find(o => o.organisationId == orgId) || null;
			
			if (org) {
				// check for course specific role
				const courseUser = course.courseUsers.find(u => u.userOrganisation.userId == user.id) || null;
				
				return (courseUser) ? courseUser.role : org.role;
				
			} else {
				// check if user belongs to ancestor organisation
				org = user.userOrganisations.find(uo => uo.decendantOrganisations.some(o => o.id == orgId)) || null;
				
				return org.role;
			}
		},
		getField,
		getCourseField(state) {			
			// return course object
			const courseId = router.currentRoute._value.params.courseId;
			const course = state.courses.find(u => u.id === parseInt(courseId));			
			return getField(course);
		},
		getCourses: (state) => {
			return state.courses;
		},
		getCourse: (state) => (courseId) => {
			// return course matching id
			return state.courses.find(c => c.id === parseInt(courseId)) || null;
		},
		getUserCourses: (state, getters, rootstate) => {
			// get courses where auth user is a course 'learner'
			let userCourses = [];
			
			for (const c of state.courses) {
				if (c.courseUsers.length) {
					for (const cu of c.courseUsers) {
						if (cu && cu.userOrganisation.userId === rootstate.auth.user.id && cu.roleId === 6) {
							userCourses.push(c);
						}
					}
				}
			}
			
			return userCourses || null;
		},
		getCourseAttempts: (state, getters, rootState) => (courseId, userId) => {
			// group activity by user attempts
			const course = getters.getCourse(courseId);
			let attempts = [];
			
			if (!course) {
				return attempts;
			}
			
			const courseUsers = course.courseUsers || [];
			const users = rootState.users.users;
			
			if (courseUsers && course.courseUserActivities) {
				for (const courseUser of courseUsers) {
					const uId = courseUser.userOrganisation.userId;
					
					if (userId && uId !== userId) {	
						continue;
					}
					
					const userActivities = course.courseUserActivities.filter(a => a.userId === uId);
					const user = users.find(u => u.id === uId) || null;
					const lessonTaskCount = course.courseLessons.map(l => {
						const tasks = l.blockData.blocks.filter(b => b.type === 'task');
						return (tasks.length);
					});
					
					const taskCount = lessonTaskCount.reduce((tot, num) => {
						return tot + num;
					}, 0);
					
					if (userActivities.length) {
						// get unique atttempt
						const uniqueAttempts = [...new Set(userActivities.map(a => a.attempt))];
						for (const attempt of uniqueAttempts) {
							const attemptActivities = userActivities.filter(a => a.attempt === attempt);
							const attemptStarted = attemptActivities.find(a => a.activityType === 'course' && a.activitySubtype === 'started');
							const attemptCompleted = attemptActivities.find(a => a.activityType === 'course' && a.activitySubtype === 'completed') || false;
							const tasks = attemptActivities.filter(a => a.activityType === 'task');
							const correctTasks = tasks.filter(a => a.activityData.taskResult === true);
							const incorrectTasks = tasks.filter(a => a.activityData.taskResult === false);
							const unmarkedTasks = tasks.filter(a => a.activityData.taskResult === null && a.activityData.taskMarked === true);					
							const courseStart = attemptActivities.find(a => a.activityType === 'course' && a.activitySubtype === 'started');
							const examMode = ( courseStart.activityData.examMode ) ? true : false;
							const examPassScore = ( courseStart.activityData.examPassScore ) ? courseStart.activityData.examPassScore : 0;
							let examMaxScore = 0;
							let examUserScore = 0;
							let examUserScorePending = 0;
							let result = null;
							
							if (examMode) {
								for (let task of tasks) {
									examMaxScore += task.activityData.examPoints;
									
									if (task.activityData.taskResult === true) {
										
										if (task.activityData.taskMarked === true) {
											// manual mark awarded
											examUserScore += task.activityData.userPoints;
											
										} else {
											// automatically marked
											examUserScore += task.activityData.examPoints;
										}
										
									} else if (task.activityData.taskResult === null && task.activityData.taskMarked === true) {
										// awaiting manual marking
										examUserScorePending += task.activityData.examPoints;
									}
								}
								
								if (attemptCompleted) {
									if (examUserScore >= examPassScore) {
										// passed
										result = true;
										
									} else if ((examUserScore + examUserScorePending) < examPassScore) {
										// failed
										result = false;
										
									} else {
										// could still pass following manual marking
										result = null;
									}
								}
								
							} else if (tasks.length && attemptCompleted) {
								result = incorrectTasks.length ? false : true;
							}
							
							attempts.push({
								attempt: attempt,
								started: attemptStarted.activityDatetime,
								completed: (attemptCompleted) ? attemptCompleted.activityDatetime : false,
								tasks: {
									total: taskCount,
									pending: unmarkedTasks.length, // manually marked taskes
									correct: correctTasks.length,
									incorrect: incorrectTasks.length,
									examMode,
									examPassScore,
									examMaxScore,
									examUserScore,
									result: result, // null = pending, false = failed, true = passed
								},
								user: user,
								courseRole: courseUser.role,
								activities: attemptActivities,
							});
						}
						
					} else {
						attempts.push({
							attempt: null,
							started: false,
							completed: false,
							tasks: {
								total: taskCount,
								pending: 0,
								correct: 0,
								incorrect: 0,
								examMode: (course.examMode) ? true : false,
								examPassScore: 0,
								examMaxScore: 0,
								examUserScore: 0,
								result: null,
							},
							user: user,
							courseRole: courseUser.role,
							activities: [],
						});
					}
				}
			}
			
			// sort by started desc
			attempts.sort((a,b) => {
				var d1 = new Date(a.started);
				var d2 = new Date(b.started);
				return d2 - d1;
			});
			
			return attempts;
		},
		getAvgFeedbackScore: (state, getters) => (courseId) => {
			const course = getters.getCourse(courseId);
			let feedback = 0;
			let score = 0;
			
			if (course && course.courseUsers) {
				for (const user of course.courseUsers) {
					if (user.feedbackScore !== null) {
						feedback ++;
						score += user.feedbackScore;
					}
				}
			}
			// round to nearest .5
			return (Math.round((score/feedback) * 2) / 2).toFixed(1);
		},
		getCompletedAttemptCount: (state, getters) => (courseId, userId) => {
			// completed 
			const course = getters.getCourse(courseId);
			let count = 0;
			
			if (course && course.courseUserActivities) {				
				if (userId) {
					count = course.courseUserActivities.filter(a => a.activityType === 'course' && a.activitySubtype === 'completed' && a.userId === userId).length;
				} else {
					count = course.courseUserActivities.filter(a => a.activityType === 'course' && a.activitySubtype === 'completed').length;					
				}
			}
			
			return count;
		},
		getCompletedPassCount: (state, getters) => (courseId, userId) => {
			const attempts = getters.getCourseAttempts(courseId, userId).filter(a => a.tasks.result === true );
			
			return attempts.length;
		},
		getCompletedFailCount: (state, getters) => (courseId, userId) => {			
			const attempts = getters.getCourseAttempts(courseId, userId).filter(a => a.tasks.result === false );
			
			return attempts.length;
		},
		getCourseMentorCount: (state, getters) => (courseId) => {			
			const course = getters.getCourse(courseId);
			return (course) ? course.courseUsers.filter(u => u.role.slug === 'mentor').length : 0;
		},
		getCourseLearnerCount: (state, getters) => (courseId) => {			
			const course = getters.getCourse(courseId);
			return (course) ? course.courseUsers.filter(u => u.role.slug === 'learner').length : 0;
		},
		getCourseLessonCount: (state, getters) => (courseId) => {			
			const course = getters.getCourse(courseId);
			return (course) ? course.courseLessons.length : 0;
		},
		getCourseTaskCount: (state, getters) => (courseId) => {			
			const course = getters.getCourse(courseId);
			let count = 0;
			
			if (course) {
				for (let lesson of course.courseLessons) {
					if (lesson.blockData.blocks.length) {
						count += lesson.blockData.blocks.filter(b => b.type === 'task').length;
					}
				}
			}
			
			return count;
		},
		getLessonBlockCount: (state, getters) => (courseId) => {			
			const course = getters.getCourse(courseId);
			let count = 0;
			
			if (course) {
				for (let lesson of course.courseLessons) {
					count += lesson.blockData.blocks.length;
				}
			}
			
			return count;
		},
	},
	mutations: {
		updateField,
		updateCourseField(state, field) {
			const courseId = router.currentRoute._value.params.courseId;
			const course = state.courses.find(u => u.id === parseInt(courseId));
			
			updateField(course, field);
			
			if (field.path === 'name') {
				// update slug
				const value = field.value.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g, '');
				
				updateField(course, {
					path: 'slug',
					value,
				});
			}			
		},		
		SET_COURSES(state, payload) {
			state.courses = payload;
		},
		SET_COURSE_PARAM(state, payload) {
			state.courses[payload.index][payload.param] = payload.value;
		},
		SET_COURSE_SECTION_RANK(state, payload) {
			if (state) {
				console.log('SET_COURSE_SECTION_RANK:payload;', payload);
			}
			state.courses[payload.courseIndex]['courseSections'][payload.sectionIndex]['rank'] = payload.rank;
		},
		SET_COURSE_LESSON_RANK(state, payload) {
			// update lesson in course.courseLessons
			state.courses[payload.courseIndex]['courseLessons'][payload.lessonIndex]['rank'] = payload.rank;
			state.courses[payload.courseIndex]['courseLessons'][payload.lessonIndex]['courseSectionId'] = payload.sectionId;
		},
		SET_COURSE_LESSON_BLOCKS(state, payload) {
			state.courses[payload.courseIndex].courseLessons[payload.lessonIndex].blockData.blocks = payload.blocks;
		},
		TOGGLE_COURSE_LESSON_BLOCK_SETTINGS(state, payload) {
			state.courses[payload.courseIndex].courseLessons[payload.lessonIndex].blockData.blocks[payload.blockIndex].settings.active = payload.active;
		},
		ADD_SECTION(state, payload) {
			state.courses[payload.courseIndex].courseSections.push(payload.section);
		},
		BIN_SECTION(state, payload) {
			state.courses[payload.courseIndex].courseSections.splice(payload.sectionIndex, 1);
		},
		ADD_LESSON(state, payload) {
			state.courses[payload.courseIndex].courseLessons.push(payload.lesson);
		},
		BIN_LESSON(state, payload) {
			state.courses[payload.courseIndex].courseLessons.splice(payload.lessonIndex, 1);
		},
		SET_SECTION_NAME(state, payload) {
			state.courses[payload.courseIndex].courseSections[payload.sectionIndex].name = payload.name;
		},
		SET_LESSON_NAME(state, payload) {
			state.courses[payload.courseIndex].courseLessons[payload.lessonIndex].name = payload.name;
		},
		SET_LESSON_TYPE(state, payload) {
			state.courses[payload.courseIndex].courseLessons[payload.lessonIndex].type = payload.type;
		},
		DESC_TEMP_ID(state) {
			state.tempId --;
		},
		ADD_COURSE_USER(state, payload) {
			state.courses[payload.courseIndex].courseUsers.push(payload.courseUser);
		},
		BIN_COURSE_USER(state, payload) {
			state.courses[payload.courseIndex].courseUsers.splice(payload.userIndex, 1);
		},
		SET_USER_ROLE(state, payload) {
			state.courses[payload.courseIndex].courseUsers[payload.userIndex].roleId = payload.roleId;
		},
		SET_USER_TASK_POINTS(state, payload) {
			state.courses[payload.courseIndex].courseUserActivities[payload.activityIndex].activityData.taskResult = payload.taskResult;
			state.courses[payload.courseIndex].courseUserActivities[payload.activityIndex].activityData.userPoints = payload.userPoints;
		},
	},
	actions: {
		assignUserRole({ commit, state }, data) {
			const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.courseId));
			const userIndex = state.courses[courseIndex].courseUsers.findIndex(u => u.userOrganisation.userId === parseInt(data.userId));

			commit('SET_USER_ROLE', {
				courseIndex: courseIndex,
				userIndex: userIndex,
				roleId: data.roleId,
			});
		},
		assignUsers({ commit, state }, data) {
			const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.courseId));

			for (const user of data.courseUsers) {
				const courseUser = {
					id: null,
					roleId: 6, // default role
					userOrganisation: {
						id: user.user.userOrganisations[0].id,
						userId: user.user.id
					}
				};

				commit('ADD_COURSE_USER', {
					courseIndex: courseIndex,
					courseUser: courseUser,
				});
			}

		},
		unassignUsers({ commit, state }, data) {
			const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.courseId));
			
			for (const courseUser of data.courseUsers) {
				const userIndex = state.courses[courseIndex].courseUsers.findIndex(u => u.userOrganisation.userId === parseInt(courseUser.user.id));
				
				commit('BIN_COURSE_USER', {
					courseIndex: courseIndex,
					userIndex: userIndex,
				});
			}
		},
		setSectionName({ commit, state }, data) {
			const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.courseId));
			const sectionIndex = state.courses[courseIndex].courseSections.findIndex(s => s.id === parseInt(data.sectionId));

			commit('SET_SECTION_NAME', {
				courseIndex: courseIndex,
				sectionIndex: sectionIndex,
				name: data.name,
			});
		},
		setLessonName({ commit, state }, data) {
			const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.courseId));
			const lessonIndex = state.courses[courseIndex].courseLessons.findIndex(l => l.id === parseInt(data.lessonId));

			commit('SET_LESSON_NAME', {
				courseIndex: courseIndex,
				lessonIndex: lessonIndex,
				name: data.name,
			});
		},
		setLessonType({ commit, state }, data) {
			const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.courseId));
			const lessonIndex = state.courses[courseIndex].courseLessons.findIndex(l => l.id === parseInt(data.lessonId));

			commit('SET_LESSON_TYPE', {
				courseIndex: courseIndex,
				lessonIndex: lessonIndex,
				type: data.type,
			});
		},
		addSection({ commit, state }, data) {
			commit('DESC_TEMP_ID');

			const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.courseId));
			const rank = (data.prepend) ? state.tempId : state.courses[courseIndex].courseSections.length + 1;

			commit('ADD_SECTION', {
				courseIndex: courseIndex,
				section: {
					// default section data
					id: state.tempId,
					courseId: data.courseId,
					name: null,
					rank: rank,
				},
			});
		},
		binSection({ commit, state }, data) {
			const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.courseId));
			const courseSections = state.courses[courseIndex]['courseSections'];
			const sectionIndex = courseSections.findIndex(s => s.id === parseInt(data.sectionId));

			commit('BIN_SECTION', {
				courseIndex: courseIndex,
				sectionIndex: sectionIndex,
			});
		},
		addLesson({ commit, state }, data) {
			commit('DESC_TEMP_ID');
			
			const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.courseId));
			const rank = (data.prepend) ? state.tempId : state.courses[courseIndex].courseLessons.length + 1;
			
			commit('ADD_LESSON', {
				courseIndex: courseIndex,
				lesson: {
					id: state.tempId,
					courseId: data.courseId,
					courseSectionId: data.sectionId,
					name: '',
					type: 'Lesson', // default
					rank: rank,
					startDatetime: null,
					endDatetime: null,
					blockData: {
						blocks: []
					},
				},
			});
		},
		binLesson({ commit, state }, data) {
			const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.courseId));
			const courseLessons = state.courses[courseIndex]['courseLessons'];
			const lessonIndex = courseLessons.findIndex(l => l.id === parseInt(data.lessonId));
			
			commit('BIN_LESSON', {
				courseIndex: courseIndex,
				lessonIndex: lessonIndex,
			});
		},
		async dbCourses({ commit }) {
			// get organisation courses from database
			try {
				const {data} = await CourseService.getCourses();
				commit('SET_COURSES', data.courses);
				
			} catch (error) {
				console.log(error);
			}
		},
		setCourseParam({ commit, state }, data) {
			const index = state.courses.findIndex(c => c.id === parseInt(data.id));
			if (index >= 0) {
				commit('SET_COURSE_PARAM', {
					index: index,
					param: data.param,
					value: data.value,
				});
			}
		},
		setCourseSectionRanks({ commit, state }, data) {
			const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.cId));
			const courseSections = state.courses[courseIndex]['courseSections'];
			const sectionIndex = courseSections.findIndex(s => s.id === parseInt(data.sId));
			
			commit('SET_COURSE_SECTION_RANK', {
				courseIndex: courseIndex,
				sectionIndex: sectionIndex,
				rank: data.rank,
			});
		},
		toggleCourseLessonBlockSettings({ commit, state }, data) {
			const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.cId));
			const courseLessons = state.courses[courseIndex]['courseLessons'];
			const lessonIndex = courseLessons.findIndex(l => l.id === parseInt(data.lId));
			const lessonBlocks = state.courses[courseIndex]['courseLessons'][lessonIndex].blockData.blocks;
			const blockIndex = lessonBlocks.findIndex(b => b.id === parseInt(data.bId));
			
			commit('TOGGLE_COURSE_LESSON_BLOCK_SETTINGS', {
				courseIndex: courseIndex,
				lessonIndex: lessonIndex,
				blockIndex: blockIndex,
				active: data.active,
			});
		},
		setCourseLessonBlocks({ commit, state }, data) {
			const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.cId));
			const courseLessons = state.courses[courseIndex]['courseLessons'];
			const lessonIndex = courseLessons.findIndex(l => l.id === parseInt(data.lId));
			
			commit('SET_COURSE_LESSON_BLOCKS', {
				courseIndex: courseIndex,
				lessonIndex: lessonIndex,
				blocks: data.blocks,
			});
		},
		setCourseLessonRanks({ commit, state }, data) {
			const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.cId));
			const courseLessons = state.courses[courseIndex]['courseLessons'];
			const lessonIndex = courseLessons.findIndex(l => l.id === parseInt(data.lId));
			
			commit('SET_COURSE_LESSON_RANK', {
				courseIndex: courseIndex,
				lessonIndex: lessonIndex,
				sectionId: data.sId,
				rank: data.rank,
				// debug below
				lId: data.lId,
				lName: data.lName,
				sName: data.sName,
			});
		},
		
		async setUserTaskPoints({ commit, state }, data) {
			try {
				const courseIndex = state.courses.findIndex(c => c.id === parseInt(data.activity.courseId));
				const activityIndex = state.courses[courseIndex].courseUserActivities.findIndex(a => a.id === parseInt(data.activity.id));
				
				await CourseService.updateCourseActivity({
					activityId: data.activity.id,
					taskResult: data.taskResult,
					userPoints: data.userPoints,
				});
				
				commit('SET_USER_TASK_POINTS', {
					courseIndex,
					activityIndex,
					taskResult: data.taskResult,
					userPoints: data.userPoints,
				});
				
			} catch (error) {
				console.log(error);
			}
		},
	},
};
