import MediaService from '../services/MediaService';

export const media = {
	namespaced: true,
	state: {
		medias: [],
		mediaGroups: [],
//		tempId: 0,
	},
	mutations: {
		SET_MEDIA(state, payload) {
			state.medias = payload;
		},
		SET_MEDIA_GROUPS(state, payload) {
			state.mediaGroups = payload;
		},
		SET_MEDIA_LOADED(state, mediaIndex) {
			state.medias[mediaIndex].loaded = true;
		},
		UNSET_MEDIA(state, mediaIndex) {
			state.medias.splice(mediaIndex, 1);
		},
		UPDATE_MEDIA(state, payload) {
			state.medias[payload.mediaIndex].name = payload.name;
			state.medias[payload.mediaIndex].seoAlt = payload.seoAlt;
			state.medias[payload.mediaIndex].seoDescription = payload.seoDescription;
			state.medias[payload.mediaIndex].credit = payload.credit;
		},
	},
	actions: {
		updateMedia({ commit, state }, data) {
			const mediaIndex = state.medias.findIndex(m => m.id === parseInt(data.mediaId));
			commit('UPDATE_MEDIA', {
				mediaIndex: mediaIndex,
				name: data.name,
				seoAlt: data.seoAlt,
				seoDescription: data.seoDescription,
				credit: data.credit,
			});
		},
		unsetMedia({ commit, state }, data) {
			const mediaIndex = state.medias.findIndex(m => m.id === parseInt(data.mediaId));
			commit('UNSET_MEDIA', mediaIndex);
		},
		setMediaLoaded({ commit, state }, data) {
			const mediaIndex = state.medias.findIndex(m => m.id === parseInt(data.mediaId));
			commit('SET_MEDIA_LOADED', mediaIndex);
		},
		async dbMedias({ commit }) {
			// get organisation media/media groups from database
			try {
				let response = await MediaService.getMedias();
				commit('SET_MEDIA', response.data.medias);

				response = await MediaService.getMediaGroups();
				commit('SET_MEDIA_GROUPS', response.data.mediaGroups);

			} catch (error) {
				console.log(error);
			}
		},
	},
	getters: {
		getMedia: (state) => (id) => {
			// return media matching id
			return state.medias.find(m => m.id === parseInt(id)) || null;
		},
	}
};
