import httpApi from '../httpApi';

class OrganisationService {
	createOrganisation(data) {
		return httpApi.put('/organisation', data);
	}
	
	getBySubdomain() {
		return httpApi.get('/organisation/subdomain');
	}
	
	updateBySubdomain(data) {
		return httpApi.post('/organisation', data);
	}
	
	updateThemeBySubdomain(data) {
		return httpApi.post('/theme', data);
	}
}

export default new OrganisationService();
